import * as React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Card,
  CardContent,
  FormControl,
  LinearProgress,
  Stack,
  Box,
  Autocomplete,
  TextField,
  Grid,
  Tooltip,
  IconButton,
} from "@mui/material";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllAgentRequests,
  getAgents,
  assignAgent,
  saveSearchBody,
} from "src/actions/agentrequests";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { agent_request_statuses } from "src/utils/common";
import RequestAgentStatusMenu from "./RequestAgentStatus";
import CustomSpinner from "src/components/CustomSpinner";
import { useEffect } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { useState } from "react";
import Close from "@mui/icons-material/Close";
import { Search as SearchIcon } from "react-feather";

export default function CartReviewContent() {
  const _UserInfo = useSelector((state) => state.users.userInfo);

  const [refresh, setRefresh] = useState(false);
  const [page, setPage] = useState(0);
  const [reload, setReload] = useState(false);
  const dispatch = useDispatch();
  const [agentForAssignment, setagentForAssignment] = useState({
    id: "",
    agentId: "",
  });

  const agentrequests = useSelector((state) => state.agentRequest);
  const agentData = useSelector((state) => state.agentRequest.agentData);
  const searchBody = useSelector((state) => state.agentRequest.searchBody);

  useEffect(() => {
    setReload(true);
    dispatch(getAllAgentRequests(page + 1, searchBody)).then((res) => {
      setReload(false);
    });
  }, [refresh, dispatch]);

  const handlePageChange = (newPage) => {
    setRefresh(!refresh);
    setPage(newPage);
  };

  useEffect(() => {
    if (agentForAssignment.id)
      dispatch(assignAgent(agentForAssignment)).then((res) => {
        setRefresh(!refresh);
      });
  }, [agentForAssignment]);

  useEffect(() => {
    dispatch(getAgents());
  }, []);

  const customToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarExport
        csvOptions={{
          fileName: "Agent Request|| Orderii",
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  );

  const columns = [
    {
      field: "number",
      headerName: "Request No",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "fullName",
      headerName: "Customer Name",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "phone",
      headerName: "Customer Phone",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "address",
      headerName: "Customer Address",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "amount",
      headerName: "Amount $",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => params.row.amount?.toLocaleString(),
    },
    {
      field: "createdAt",
      headerName: "Request Time",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) =>
        moment(params.value).format("yyyy-MM-DD / hh:mm a"),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      headerAlign: "center",
      valueGetter: (params) => params.row.statusString,
      renderCell: (params) => (
        <Stack direction="row" spacing={1}>
          <RequestAgentStatusMenu
            requestAgentId={params.row.id}
            status={params.row.status}
            onSuccess={() => setRefresh(!refresh)}
          />
        </Stack>
      ),
    },
    {
      field: "agent",
      headerName: "Agent",
      flex: 1.5,
      headerAlign: "center",
      renderCell: (params) => (
        <Stack direction="row" spacing={1}>
          {agentData && (
            <FormControl sx={{ minWidth: 120 }} size="small">
              <Select
                disabled={
                  _UserInfo?.roleOEndpoint?.filter(
                    (api) => api.Code === "04-03"
                  )[0]
                    ? false
                    : true
                }
                size="small"
                value={params.row.agent}
                onChange={(e) => {
                  let agent = {};
                  agentData.map((v) => {
                    if (v.fullName === e.target.value) {
                      agent = v;
                    }
                  });
                  params.row.agent = agent.fullName;
                  setagentForAssignment({
                    id: params.row.id,
                    // status: 1,
                    agentId: agent.id,
                  });
                }}
              >
                {agentData.map((agent) => (
                  <MenuItem key={agent.id} value={agent.fullName}>
                    {agent.fullName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Stack>
      ),
    },
  ];

  return (
    <Card>
      <Box>
        <Card>
          <CardContent style={{ paddingBottom: 15 }}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Autocomplete
                  options={agent_request_statuses.filter((x) => x.id !== 9)}
                  getOptionLabel={(option) => option.name}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Status" />
                  )}
                  onChange={(event, value) => {
                    dispatch(
                      saveSearchBody({
                        ...searchBody,
                        status: value ? value.id : null,
                      })
                    );
                    setPage(0);
                    setRefresh(!refresh);
                  }}
                  value={
                    agent_request_statuses.filter(
                      (x) => x.id == searchBody.status
                    )[0] ?? null
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  options={agentData}
                  getOptionLabel={(option) => option.fullName}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Agent" />
                  )}
                  onChange={(event, value) => {
                    dispatch(
                      saveSearchBody({
                        ...searchBody,
                        agentId: value ? value.id : null,
                      })
                    );
                    setPage(0);
                    setRefresh(!refresh);
                  }}
                  value={
                    agentData.filter((x) => x.id == searchBody.agentId)[0] ??
                    null
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="Customer Phone Number"
                  variant="outlined"
                  type="number"
                  value={searchBody.customerPhoneNumber}
                  sx={{ marginRight: 1 }}
                  onChange={(e) => {
                    var phoneNumber = e.target.value;
                    dispatch(
                      saveSearchBody({
                        ...searchBody,
                        customerPhoneNumber:
                          phoneNumber[0] == "0"
                            ? phoneNumber.substring(1)
                            : phoneNumber,
                      })
                    );
                  }}
                  onKeyDown={(e) => {
                    if (e.key == "Enter") {
                      setPage(0);
                      setRefresh(!refresh);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <Stack direction="row" spacing={1}>
                  <Tooltip title="Search with filter">
                    <IconButton
                      onClick={() => {
                        dispatch(
                          saveSearchBody({
                            ...searchBody,
                          })
                        );
                        setPage(0);
                        setRefresh(!refresh);
                      }}
                    >
                      <SearchIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Clear filter">
                    <IconButton
                      onClick={() => {
                        dispatch(
                          saveSearchBody({
                            pageSize: 15,
                            status: null,
                            customerPhoneNumber: "",
                            agentId: null,
                          })
                        );
                        setPage(0);
                        setRefresh(!refresh);
                      }}
                    >
                      <Close />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
      <PerfectScrollbar>
        <Box>
          {!agentrequests ? (
            <CustomSpinner />
          ) : (
            <Box
              sx={{
                height: { md: "calc(100vh - 270px)", xs: 1000 },
                minWidth: 1050,
              }}
            >
              <DataGrid
                sx={{
                  "& .css-204u17-MuiDataGrid-main": {
                    paddingLeft: "15px",
                  },
                  "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                    paddingLeft: "15px",
                  },
                  ".MuiDataGrid-cell": {
                    justifyContent: "center",
                  },
                }}
                loading={reload}
                rows={agentrequests.data}
                getRowId={(params) => params.id}
                columns={columns}
                rowHeight={70}
                disableSelectionOnClick
                pagination
                pageSize={searchBody.pageSize}
                page={page}
                onPageChange={(newPage) => handlePageChange(newPage)}
                onPageSizeChange={(newPage) => {
                  dispatch(
                    saveSearchBody({
                      ...searchBody,
                      pageSize: newPage,
                    })
                  );
                  setRefresh(!refresh);
                }}
                rowCount={agentrequests.rowCount}
                paginationMode="server"
                rowsPerPageOptions={[15, 25, 75, 100]}
                components={{
                  LoadingOverlay: LinearProgress,
                  Toolbar: customToolbar,
                }}
              />
            </Box>
          )}
        </Box>
      </PerfectScrollbar>
    </Card>
  );
}
