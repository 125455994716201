import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Chip, Icon } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { gift_order_statuses } from "src/utils/common";
import { useState } from "react";
import { updateOrderStatus } from "src/actions/gifts/orders";

export default function OrderStatusMenu({
  size = "small",
  onCancel = function () {},
  status = "",
  statusNumber = "",
  onSuccess = function () {},
  onError = function () {},
  orderId = null,
}) {
  const _UserInfo = useSelector((state) => state.users.userInfo);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();

  function updateStatus(e) {
    dispatch(updateOrderStatus(orderId, e.target.id)).then((res) => {
      onSuccess("Order status changed successfully");
    });
  }

  const getStatusIcon = (statusNumber) => {
    return (
      <Chip
        size="small"
        label={
          gift_order_statuses.filter((data) => data.id === statusNumber)[0]
            ?.name
        }
        color={
          gift_order_statuses.filter((data) => data.id === statusNumber)[0]
            ?.color
        }
        clickable
        onClick={handleClick}
      />
    );
  };

  return (
    <React.Fragment>
      {getStatusIcon(statusNumber)}
      <Menu
        anchorEl={anchorEl}
        open={
          !_UserInfo.roleOEndpoint.map((data) => data.Code).includes("47-08")
            ? false
            : open
        }
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {gift_order_statuses.map((status) => (
          <MenuItem
            key={status.id}
            id={status.id}
            onClick={status.name === "Canceled" ? onCancel : updateStatus}
            disabled={
              status.id === 7 ||
              status.id === 8 ||
              status.id === 9 ||
              status.id === 10 ||
              status.id === 4
            }
          >
            <ListItemIcon>
              <Icon>{status.icon}</Icon>
            </ListItemIcon>
            {status.name}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
}
