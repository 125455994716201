import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Card,
  TextField,
  Tooltip,
  Grid,
  CardContent,
  LinearProgress,
  InputAdornment,
  Typography,
  Stack,
  Icon,
} from "@mui/material";
import { AiOutlineSearch } from "react-icons/ai";
import IconButton from "@mui/material/IconButton";
import CustomNoRowsOverlay from "src/components/CustomNoRowsOverlay";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import useCanShow from "src/components/CanShow";
import { useNavigate } from "react-router";
import {
  getAllHiExpressOrders,
  saveHiExpressPageNo,
  saveHiExpressSearchBody,
} from "src/actions/hiExpress";

const HiExpressTable = () => {
  const dispatch = useDispatch();

  let navigate = useNavigate();

  const orderDetailsRole = useCanShow("22-03");

  const hiExpressData = useSelector((state) => state.hiExpress.data);
  const loading = useSelector((state) => state.hiExpress.reload);
  const searchBody = useSelector((state) => state.hiExpress.searchBody);
  const _PageNo = useSelector((state) => state.hiExpress.pageNo);

  const [page, setPage] = useState(_PageNo ?? 0);
  const [search, setSearch] = useState({
    phoneNumber: searchBody.phoneNumber,
    orderNumber: searchBody.orderNumber,
  });

  useEffect(() => {
    dispatch(getAllHiExpressOrders(page + 1, searchBody));
  }, [page, searchBody]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
    dispatch(saveHiExpressPageNo(newPage));
  };

  const columns = [
    {
      field: "#",
      headerName: "#",
      flex: 0.3,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "number",
      headerName: "Order Number",
      flex: 1,
      renderCell: (params) => params.row.order?.number,
    },
    {
      field: "user",
      headerName: "User",
      flex: 1,
      renderCell: (params) => (
        <Stack>
          <Typography variant="body">{params.row.order?.user?.name}</Typography>
          <Typography variant="caption">
            {params.row.order?.user?.phoneNumber}
          </Typography>
        </Stack>
      ),
    },
    {
      field: "itemsCount",
      headerName: "Items Count",
      flex: 1,
      renderCell: (params) => params.row.itemsCount,
    },
    {
      field: "orderValue",
      headerName: "Order Value (IQD)",
      flex: 1,
      renderCell: (params) => params.row.orderValue?.toLocaleString(),
    },
    {
      field: "quantity",
      headerName: "Quantity",
      flex: 1,
      renderCell: (params) => params.row.order.quantity,
    },
    {
      field: "destination",
      headerName: "Destination",
      flex: 1,
      renderCell: (params) => params.row.order?.destination,
    },
    {
      field: "isGift",
      headerName: "Order Type",
      flex: 1,
      renderCell: (params) =>
        params.row.order.isGift ? "Orderii Now" : "Global",
    },
    {
      field: "isCod",
      headerName: "Cod",
      flex: 1,
      renderCell: (params) => (
        <Typography
          variant="body"
          color={params.row.order.isCod ? "green" : "error"}
        >
          {params.row.order.isCod ? "Yes" : "No"}
        </Typography>
      ),
    },
    {
      field: "createdAt",
      headerName: "Date",
      flex: 0.5,
      renderCell: (params) => (
        <Stack>
          <Typography variant="body">
            {moment(params.row.createdAt).format("YYYY-MM-DD")}
          </Typography>
          <Typography variant="caption">
            {moment(params.row.createdAt).format("hh:mm a")}
          </Typography>
        </Stack>
      ),
    },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Icon color="primary">visibility</Icon>}
          label="Order Details"
          hidden={orderDetailsRole ? false : true}
          showInMenu
          onClick={(e) => {
            navigate(`/app/delivery/order/${params.row.id}`);
          }}
        />,
      ],
    },
  ];

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <TextField
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AiOutlineSearch />
                  </InputAdornment>
                ),
              }}
              placeholder="Search Order Number"
              sx={{ marginRight: 1 }}
              variant="outlined"
              value={search.orderNumber}
              onChange={(e) => {
                setSearch({
                  ...search,
                  orderNumber: e.target.value,
                });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(
                    saveHiExpressSearchBody({
                      ...searchBody,
                      orderNumber: search.orderNumber,
                    })
                  );
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AiOutlineSearch />
                  </InputAdornment>
                ),
              }}
              placeholder="Search Phone Number"
              sx={{ marginRight: 1 }}
              variant="outlined"
              value={search.phoneNumber}
              onChange={(e) => {
                setSearch({
                  ...search,
                  phoneNumber: e.target.value,
                });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(
                    saveHiExpressSearchBody({
                      ...searchBody,
                      phoneNumber: search.phoneNumber,
                    })
                  );
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Tooltip title="Clear filter">
              <IconButton
                onClick={() => {
                  setPage(0);
                  dispatch(saveHiExpressPageNo(0));
                  setSearch({
                    orderNumber: "",
                    phoneNumber: "",
                    pageSize: 15,
                  });
                  dispatch(
                    saveHiExpressSearchBody({
                      ...searchBody,
                      orderNumber: "",
                      phoneNumber: "",
                      pageSize: 15,
                    })
                  );
                }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <PerfectScrollbar>
              <Box sx={{ height: { md: "calc(100vh - 270px)", xs: 1000 } }}>
                <DataGrid
                  columns={columns}
                  rows={hiExpressData?.data}
                  loading={loading}
                  disableSelectionOnClick
                  rowHeight={100}
                  pagination
                  paginationMode="server"
                  rowCount={hiExpressData.rowCount}
                  pageSize={searchBody.pageSize}
                  page={page}
                  onPageChange={(newPage) => handlePageChange(newPage)}
                  onPageSizeChange={(newPageSize) =>
                    dispatch(
                      saveHiExpressSearchBody({
                        ...searchBody,
                        pageSize: newPageSize,
                      })
                    )
                  }
                  rowsPerPageOptions={[5, 15, 25, 75, 100]}
                  components={{
                    LoadingOverlay: LinearProgress,
                    NoRowsOverlay: CustomNoRowsOverlay,
                  }}
                />
              </Box>
            </PerfectScrollbar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default HiExpressTable;
