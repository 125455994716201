export const LOGIN_AUTH = "LOGIN_AUTH";

export const CREATE_USER = "CREATE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const GET_USER_BY_ID = "GET_USER_BY_ID";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const CHANGE_STATUS = "CHANGE_STATUS";
export const SAVE_SEARCH_BODY_USERS = "SAVE_SEARCH_BODY_USERS";
export const SAVE_USERS_PAGE_NUM = "SAVE_USERS_PAGE_NUM";
export const OPEN_USERS_DEALOG = "OPEN_USERS_DEALOG";
export const OPEN_NOTIFY_USERS_DIALOG = "OPEN_NOTIFY_USERS_DIALOG";
export const GET_BY_ID_USERS = "GET_BY_ID_USERS";
export const RESET_FORM_USERS = "RESET_FORM_USERS";
export const USER_INFO = "USER_INFO";
export const SIDEBAR_DASHBOARD = "SIDEBAR_DASHBOARD";
export const USER_STATUS = "USER_STATUS";
export const LOADING_USERS = "LOADING_USERS";
export const RELOAD_USER_INFO = "RELOAD_USER_INFO";
export const RELOAD_USER_GET_BY_TOKEN = "RELOAD_USER_GET_BY_TOKEN";
export const FILTERS_TYPE = "FILTERS_TYPE";
export const OPEN_PHONE_CODE_DIALOG = "OPEN_PHONE_CODE_DIALOG";
export const OPEN_PHONE_CODE_DATA = "OPEN_PHONE_CODE_DATA";
export const OPEN_DEVICE_INFO_DIALOG = "OPEN_DEVICE_INFO_DIALOG";

export const CREATE_VENDOR = "CREATE_VENDOR";
export const UPDATE_VENDOR = "UPDATE_VENDOR";
export const GET_ALL_VENDORS = "GET_ALL_VENDORS";
export const GET_ALL_VENDORS_LIST = "GET_ALL_VENDORS_LIST";
export const CHANGE_VENDOR_STATUS = "CHANGE_VENDOR_STATUS";
export const ACTIVATE_TRANSLATION = "ACTIVATE_TRANSLATION";
export const UPDATE_PROPERTIES = "UPDATE_PROPERTIES";
export const DELETE_VENDOR = "DELETE_VENDOR";
export const OPEN_VENDOR_DIALOG = "OPEN_VENDOR_DIALOG";
export const OPEN_CATEGORY_VENDOR_DIALOG = "OPEN_CATEGORY_VENDOR_DIALOG";
export const OPEN_VENDOR_CATEGORY_DIALOG = "OPEN_VENDOR_CATEGORY_DIALOG";
export const GET_BY_ID_VENDOR = "GET_BY_ID_VENDOR";
export const RESET_FORM_VENDOR = "RESET_FORM_VENDOR";
export const RELOAD_VENDORS = "RELOAD_VENDORS";

export const GET_ALL_ORDERS = "GET_ALL_ORDERS";
export const GET_USER_ORDERS = "GET_USER_ORDERS";
export const UPDATE_ORDER_STATUS = "UPDATE_ORDER_STATUS";
export const GET_ORDER_DETAILS = "GET_ORDER_DETAILS";
export const RELOAD_ORDER_ORDER_DETAILS = "RELOAD_ORDER_ORDER_DETAILS";

export const GET_ALL_ORDER_ITEMS = "GET_ALL_ORDER_ITEMS";
export const SAVE_SEARCH_BODY_ITEMS = "SAVE_SEARCH_BODY_ITEMS";
export const RELOAD_ORDER_ITEM = "RELOAD_ORDER_ITEM";
export const SET_SELECTED_ORDER_ITEMS = "SET_SELECTED_ORDER_ITEMS";

// export const UPDATE_ORDER_STATUS = 'UPDATE_ORDER_STATUS'

export const CREATE_PROMO = "CREATE_PROMO";
export const GET_ALL_PROMOS = "GET_ALL_PROMOS";
export const UPDATE_PROMO = "UPDATE_PROMO";
export const DELETE_PROMO = "DELETE_PROMO";
export const CHANGE_PROMO_STATUS = "CHANGE_PROMO_STATUS";
export const GET_BY_ID_PROMO = "GET_BY_ID_PROMO";
export const RESET_FORM_PROMO = "RESET_FORM_PROMO";
export const OPEN_PROMO_DIALOG = "OPEN_PROMO_DIALOG";
export const RELOAD_PROMO = "RELOAD_PROMO";
export const SAVE_PROMO_SAVE_NO = "SAVE_PROMO_SAVE_NO";
export const SAVE_PROMO_PAGE_SIZE = "SAVE_PROMO_PAGE_SIZE";
export const SAVE_PROMO_SEARCH_BODY = "SAVE_PROMO_SEARCH_BODY";

export const CREATE_EXPENSE = "CREATE_EXPENSE";
export const GET_ALL_EXPENSES = "GET_ALL_EXPENSES";
export const UPDATE_EXPENSE = "UPDATE_EXPENSE";
export const DELETE_EXPENSE = "DELETE_EXPENSE";

export const CREATE_EXPENSE_TYPE = "CREATE_EXPENSE_TYPE";
export const GET_ALL_EXPENSE_TYPES = "GET_ALL_EXPENSE_TYPES";
export const DELETE_EXPENSE_TYPE = "DELETE_EXPENSE_TYPE";

export const GET_ALL_ADDRESSES = "GET_ALL_ADDRESSES";
export const CREATE_ADDRESS = "CREATE_ADDRESS";
export const UPDATE_ADDRESS = "UPDATE_ADDRESS";
export const DELETE_ADDRESS = "DELRTR_ADDRESS";

export const CREATE_BANNER = "CREATE_BANNER";
export const DELETE_BANNER = "DELETE_BANNER";
export const GET_ALL_BANNERS = "GET_ALL_BANNERS";
export const UPDATE_BANNER = "UPDATE_BANNER";
export const GET_BY_ID_BANNERS = "GET_BY_ID_BANNERS";
export const RESET_FORM_BANNERS = "RESET_FORM_BANNERS";
export const SAVE_BANNERS_PAGE_NO = "SAVE_BANNERS_PAGE_NO";
export const OPEN_BANNER_DIALOG = "OPEN_BANNER_DIALOG";
export const RELOAD_BANNER = "RELOAD_BANNER";
export const SAVE_SEARCH_BODY_BANNERS = "SAVE_SEARCH_BODY_BANNERS";

export const GET_ALL_ORDER_REASONS = "GET_ALL_ORDER_REASONS";
export const CREATE_ORDER_REASONS = "CREATE_ORDER_REASONS";
export const UPDATE_ORDER_REASONS = "UPDATE_ORDER_REASONS";
export const DELETE_ORDER_REASONS = "DELETE_ORDER_REASONS";
export const SAVE_SEARCH_BODY_ORDERS = "SAVE_SEARCH_BODY_ORDERS";
export const SAVE_PAGE_NUM = "SAVE_PAGE_NUM";
export const SAVE_ITEMS_PAGE_NUM = "SAVE_ITEMS_PAGE_NUM";
export const SAVE_BANNERS_PAGE_SIZE = "SAVE_BANNERS_PAGE_SIZE";
export const GET_NOTE_HISTORY = "GET_NOTE_HISTORY";
export const OPEN_NOTE_HISTORY_DIALOG = "OPEN_NOTE_HISTORY_DIALOG";
export const SAVE_NOTE_HISTORY_SEARCH_BODY = "SAVE_NOTE_HISTORY_SEARCH_BODY";
export const SAVE_NOTE_HISTORY_PAGE_NO = "SAVE_NOTE_HISTORY_PAGE_NO";

export const GET_ALL_ITEM_REASONS = "GET_ALL_ITEM_REASONS";
export const GET_ALL_ITEM_REASONS_LIST = "GET_ALL_ITEM_REASONS_LIST";
export const CREATE_ITEM_REASONS = "CREATE_ITEM_REASONS";
export const UPDATE_ITEM_REASONS = "UPDATE_ITEM_REASONS";
export const DELETE_ITEM_REASONS = "DELETE_ITEM_REASONS";

export const GET_ALL_SHIPPING_CONFIGS = "GET_ALL_SHIPPING_CONFIGS";
export const UPDATE_SHIPPING_CONFIGS = "UPDATE_SHIPPING_CONFIGS";
export const SAVE_USERS_PAGE_SC = "SAVE_USERS_PAGE_SC";
export const SAVE_SEARCH_BODY_SC = "SAVE_SEARCH_BODY_SC";
export const OPEN_SHIPPING_CONFIGS_DIALOG = "OPEN_SHIPPING_CONFIGS_DIALOG";
export const GET_BY_ID_SHIPPING_CONFIGS = "GET_BY_ID_SHIPPING_CONFIGS";
export const RESET_FORM_SHIPPING_CONFIGS = "RESET_FORM_SHIPPING_CONFIGS";
export const SHIPPINGS_CONFIGS_RELOAD = "SHIPPINGS_CONFIGS_RELOAD   ";

export const GET_ALL_MOBILE_APP_VERSIONS = "GET_ALL_MOBILE_APP_VERSIONS";
export const CREATE_MOBILE_APP_VERSION = "CREATE_MOBILE_APP_VERSION";
export const DELETE_MOBILE_APP_VERSION = "DELETE_MOBILE_APP_VERSION";
export const UPDATE_MOBILE_APP_VERSION = "UPDATE_MOBILE_APP_VERSION";

export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";
export const SHOW_SNACKBAR = "SHOW_SNACKBAR";
export const OPEN_ALERT_DIALOG = "OPEN_ALERT_DIALOG";
export const CLOSE_ALERT_DIALOG = "CLOSE_ALERT_DIALOG";

export const GET_ALL_INVOICES = "GET_ALL_INVOICES";
export const GET_NEW_ORDERS = "GET_NEW_ORDERS";
export const GET_INVOICE_DETAILS = "GET_INVOICE_DETAILS";
export const GET_NEW_ORDER_DETAILS = "GET_NEW_ORDER_DETAILS";

export const GET_ALL_TICKETS = "GET_ALL_TICKETS";
export const SAVE_SEARCH_BODY_TICKETS = "SAVE_SEARCH_BODY_TICKETS";
export const GET_TICKET_DETAILS = "GET_TICKET_DETAILS";
export const RELOAD_TICKET_DETAILS = "RELOAD_TICKET_DETAILS";
export const OPEN_TICKET_DEALOG = "OPEN_TICKET_DEALOG";
export const REST_TICKET_DEALOG = "REST_TICKET_DEALOG";
export const RELOAD_TICKET_TABLE = "RELOAD_TICKET_TABLE";
export const ASSIGN_TICKET = "ASSIGN_TICKET";
export const UNASSIGN_TICKET = "UNASSIGN_TICKET";
export const DELETE_TICKET_ATTACHMENT = "DELETE_TICKET_ATTACHMENT";
export const SAVE_SEARCH_PAGE_NO = "SAVE_SEARCH_PAGE_NO";
export const GET_TICKET_TIMLINE = "GET_TICKET_TIMLINE";

export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const GET_ALL_CATEGORY = "GET_ALL_CATEGORY";
export const GET_CATEGORY_BYID = "GET_CATEGORY_BYID";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const OPEN_CATEGORY_DIALOG = "OPEN_CATEGORY_DIALOG";
export const RELOAD_CATEGORY = "RELOAD_CATEGORY";
export const UPDATE_TICKET_STATUS = "UPDATE_TICKET_STATUS";
export const UPDATE_TICKET_PRIORITY = "UPDATE_TICKET_PRIORITY";
export const TICKET_CATEGORY_PAGE_NO = "TICKET_CATEGORY_PAGE_NO";
export const GET_ALL_CATEGORY_LIST = "GET_ALL_CATEGORY_LIST";
export const RESET_FORM_CATEGORY = "RESET_FORM_CATEGORY";

export const CREATE_COMMENT = "CREATE_COMMENT";
export const UPLOAD_ATTACHMENT = "UPLOAD_ATTACHMENT";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const GET_COMMENT_DEATAILS = "GET_COMMENT_DEATAILS";

export const GET_ALL_DEPARTMENT = "GET_ALL_DEPARTMENT";
export const UPDATE_DEPARTMENT = "UPDATE_DEPARTMENT";
export const DELETE_DEPARTMENT = "DELETE_DEPARTMENT";
export const CREATE_DEPARTMENT = "CREATE_DEPARTMENT";
export const OPEN_DEPARTMENT_DIALOG = "OPEN_DEPARTMENT_DIALOG";
export const GET_DEPARTMENT_BYID = "GET_DEPARTMENT_BYID";

export const GET_ALL_EMPLOYEE = "GET_ALL_EMPLOYEE";
export const GET_EMPLOYEE_BYID = "GET_EMPLOYEE_BYID";
export const UPDATE_EMPLOYEE = "UPDATE_EMPLOYEE";
export const DELETE_EMPLOYEE = "DELETE_EMPLOYEE";
export const CREATE_EMPLOYEE = "CREATE_EMPLOYEE";
export const OPEN_EMPLOYEE_DIALOG = "OPEN_EMPLOYEE_DIALOG";

export const CREATE_DEPARTMENTEMPLOYEE = "CREATE_DEPARTMENTEMPLOYEE";
export const GET_ALL_DEPARTMENTEMPLOYEE = "GET_ALL_DEPARTMENTEMPLOYEE";
export const DELETE_DEPARTMENTEMPLOYEE = "DELETE_DEPARTMENTEMPLOYEE";
export const ERROR_DEPARTMENTEMPLOYEE = "ERROR_DEPARTMENTEMPLOYEE";

export const TICKET_SUBMITTED = "TICKET_SUBMITTED";
export const TICKET_SUMMARY = "TICKET_SUMMARY";
export const TICKET_LAST_DAYS = "TICKET_LAST_DAYS";

export const OPEN_IMAGE_UPLOAD_DEALOG = "OPEN_IMAGE_UPLOAD_DEALOG";
export const CREATE_SHARE_CENTER = "CREATE_SHARE_CENTER";
export const GET_ALL_SHARE_CENTER = "GET_ALL_SHARE_CENTER";
export const SAVE_SEARCH_BODY_SHARE_CENTER = "SAVE_SEARCH_BODY_SHARE_CENTER";
export const GET_BY_ID = "GET_BY_ID";
export const UPDATE_SHARE_CENTER = "UPDATE_SHARE_CENTER";
export const DELETE_SHARE_CENTER = "DELETE_SHARE_CENTER";
export const RELOAD_SHARE_CENTER = "RELOAD_SHARE_CENTER";
export const SAVE_SEARCH_PAGE_NO_SC = "SAVE_SEARCH_PAGE_NO_SC";
export const SHARE_CENTER_RELOAD = "SHARE_CENTER_RELOAD";

export const OPEN_EXTERNAL_ITEM_DEALOG = "OPEN_EXTERNAL_ITEM_DEALOG";
export const CREATE_EXTERNAL_ITEM = "CREATE_EXTERNAL_ITEM";
export const GET_ALL_EXTERNAL_ITEM = "GET_ALL_EXTERNAL_ITEM";
export const SAVE_SEARCH_BODY_EXTERNAL_ITEM = "SAVE_SEARCH_BODY_EXTERNAL_ITEM";
export const GET_BY_ID_EXTERNAL_ITEM = "GET_BY_ID_EXTERNAL_ITEM";
export const UPDATE_STATUS_EXTERNAL_ITEM = "UPDATE_STATUS_EXTERNAL_ITEM";
export const CANCEL_EXTERNAL_ITEM = "CANCEL_EXTERNAL_ITEM";
export const RELOAD_EXTERNAL_ITEM = "RELOAD_EXTERNAL_ITEM";
export const SAVE_SEARCH_PAGE_NO_EI = "SAVE_SEARCH_PAGE_NO_EI";
export const SAVE_CANCEL_REASON_ID = "SAVE_CANCEL_REASON_ID";
export const OPEN_EXTERNAL_ITEM_NOTE_DIALOG = "OPEN_EXTERNAL_ITEM_NOTE_DIALOG";

export const GET_ALL_AGENT_REQUEST = "GET_ALL_AGENT_REQUEST";
export const SAVE_SEARCH_BODY_AGENT_REQUEST = "SAVE_SEARCH_BODY_AGENT_REQUEST";
export const GET_ALL_AGENTS = "GET_ALL_AGENTS";
export const UPDATE_STATUS_AGENT_REQUEST = "UPDATE_STATUS_AGENT_REQUEST";
export const ASSIGN_AGENT_REQUEST = "ASSIGN_AGENT_REQUEST";
export const RELOAD_AGENT_REQUEST = "RELOAD_AGENT_REQUEST";
export const SAVE_SEARCH_PAGE_NO_AR = "SAVE_SEARCH_PAGE_NO_AR";

export const OPEN_GENERAL_SETTING_DEALOG = "OPEN_GENERAL_SETTING_DEALOG";
export const CREATE_GENERAL_SETTINGR = "CREATE_GENERAL_SETTINGR";
export const GET_ALL_GENERAL_SETTINGR = "GET_ALL_GENERAL_SETTINGR";
export const SAVE_SEARCH_BODY_GENERAL_SETTINGR =
  "SAVE_SEARCH_BODY_GENERAL_SETTINGR";
export const GET_BY_ID_GENERAL_SETTING = "GET_BY_ID_GENERAL_SETTING";
export const UPDATE_GENERAL_SETTINGR = "UPDATE_GENERAL_SETTINGR";
export const DELETE_GENERAL_SETTINGR = "DELETE_GENERAL_SETTINGR";
export const RELOAD_GENERAL_SETTINGR = "RELOAD_GENERAL_SETTINGR";
export const SAVE_SEARCH_PAGE_NO_GS = "SAVE_SEARCH_PAGE_NO_SC";
export const RESET_FORM_GS = "RESET_FORM_GS";

export const RELOAD_CART = "RELOAD_CART";
export const OPEN_CART_DIALOG = "OPEN_CART_DIALOG";
export const GET_ALL_CART = "GET_ALL_CART";
export const GET_BY_ID_CART = "GET_BY_ID_CART";
export const RESET_FORM_CART = "RESET_FORM_CART";
export const SAVE_SEARCH_BODY_CART = "SAVE_SEARCH_BODY_CART";
export const GET_CART_HISTORY = "GET_CART_HISTORY";
export const OPEN_CART_HISTORY_DIALOG = "OPEN_CART_HISTORY_DIALOG";
export const SAVE_CART_HISTORY_SEARCH_BODY = "SAVE_CART_HISTORY_SEARCH_BODY";
export const SAVE_CART_HISTORY_PAGE_NO = "SAVE_CART_HISTORY_PAGE_NO";
export const GET_CART_EXTERNAL_ITEMS = "GET_CART_EXTERNAL_ITEMS";
export const ADD_EXTERNAL_ITEM_TO_CART = "ADD_EXTERNAL_ITEM_TO_CART";
export const OPEN_CART_EXTERNAL_ITEMS_DIALOG =
  "OPEN_CART_EXTERNAL_ITEMS_DIALOG";
export const RELOAD_CART_EXTERNAL_ITEMS = "RELOAD_CART_EXTERNAL_ITEMS";
export const SAVE_CART_EXTERNAL_ITEMS_SEARCH_BODY =
  "SAVE_CART_EXTERNAL_ITEMS_SEARCH_BODY";
export const SAVE_CART_EXTERNAL_ITEMS_PAGE_NO =
  "SAVE_CART_EXTERNAL_ITEMS_PAGE_NO";

export const OPEN_EPAYMENTS_DIALOG = "OPEN_EPAYMENTS_DIALOG";
export const GET_ALL_EPAYMENTS = "GET_ALL_EPAYMENTS";
export const SAVE_SEARCH_BODY_EPAYMENTS = "SAVE_SEARCH_BODY_EPAYMENTS";
export const GET_BY_ID_EPAYMENTS = "GET_BY_ID_EPAYMENTS";
export const RELOAD_EPAYMENTS_ = "RELOAD_EPAYMENTS";
export const SAVE_SEARCH_PAGE_NO_EPAYMENTS = "SAVE_SEARCH_PAGE_NO_EPAYMENTS";
export const RESET_FORM_EPAYMENTS = "RESET_FORM_EPAYMENTS";

export const GET_ALL_REFERRAL_CODE = "GET_ALL_REFERRAL_CODE";
export const RELOAD_REFERRAL_CODE = "RELOAD_REFERRAL_CODE";
export const SAVE_SEARCH_BODY_REFERRAL_CODE = "SAVE_SEARCH_BODY_REFERRAL_CODE";
export const SAVE_REFERRAL_CODE_PAGE_NO = "SAVE_REFERRAL_CODE_PAGE_NO";

export const USER_DEACTIVATION_REASONS_GET_ALL =
  "USER_DEACTIVATION_REASONS_GET_ALL";
export const USER_DEACTIVATION_REASONS_GET_ALL_LIST =
  "USER_DEACTIVATION_REASONS_GET_ALL_LIST";
export const USER_DEACTIVATION_REASONS_RELOAD =
  "USER_DEACTIVATION_REASONS_RELOAD";
export const USER_DEACTIVATION_REASONS_OPEN_DIALOG =
  "USER_DEACTIVATION_REASONS_OPEN_DIALOG";
export const USER_DEACTIVATION_REASONS_PAGE_NUMBER =
  "USER_DEACTIVATION_REASONS_PAGE_NUMBER";
export const USER_DEACTIVATION_REASONS_GET_BY_ID =
  "USER_DEACTIVATION_REASONS_GET_BY_ID";
export const USER_DEACTIVATION_REASONS_RESET_FORM =
  "USER_DEACTIVATION_REASONS_RESET_FORM";

export const ORDER_CANCEL_REASONS_GET_ALL = "ORDER_CANCEL_REASONS_GET_ALL";
export const ORDER_CANCEL_REASONS_GET_ALL_LIST =
  "ORDER_CANCEL_REASONS_GET_ALL_LIST";
export const ORDER_CANCEL_REASONS_RELOAD = "ORDER_CANCEL_REASONS_RELOAD";
export const ORDER_CANCEL_REASONS_OPEN_DIALOG =
  "ORDER_CANCEL_REASONS_OPEN_DIALOG";
export const ORDER_CANCEL_REASONS_PAGE_NUMBER =
  "ORDER_CANCEL_REASONS_PAGE_NUMBER";
export const ORDER_CANCEL_REASONS_GET_BY_ID = "ORDER_CANCEL_REASONS_GET_BY_ID";
export const ORDER_CANCEL_REASONS_RESET_FORM =
  "ORDER_CANCEL_REASONS_RESET_FORM";

export const ITEM_CANCEL_REASONS_GET_ALL = "ITEM_CANCEL_REASONS_GET_ALL";
export const ITEM_CANCEL_REASONS_GET_ALL_LIST =
  "ITEM_CANCEL_REASONS_GET_ALL_LIST";
export const ITEM_CANCEL_REASONS_RELOAD = "ITEM_CANCEL_REASONS_RELOAD";
export const ITEM_CANCEL_REASONS_OPEN_DIALOG =
  "ITEM_CANCEL_REASONS_OPEN_DIALOG";
export const ITEM_CANCEL_REASONS_PAGE_NUMBER =
  "ITEM_CANCEL_REASONS_PAGE_NUMBER";
export const ITEM_CANCEL_REASONS_GET_BY_ID = "ITEM_CANCEL_REASONS_GET_BY_ID";
export const ITEM_CANCEL_REASONS_RESET_FORM = "ITEM_CANCEL_REASONS_RESET_FORM";

export const POP_UP_POST_GET_ALL = "POP_UP_POST_GET_ALL";
export const POP_UP_POST_GET_ALL_LIST = "POP_UP_POST_GET_ALL_LIST";
export const POP_UP_POST_RELOAD = "POP_UP_POST_RELOAD";
export const POP_UP_POST_OPEN_DIALOG = "POP_UP_POST_OPEN_DIALOG";
export const POP_UP_POST_PAGE_NUMBER = "POP_UP_POST_PAGE_NUMBER";
export const POP_UP_POST_GET_BY_ID = "POP_UP_POST_GET_BY_ID";
export const POP_UP_POST_RESET_FORM = "POP_UP_POST_RESET_FORM";

export const AGENT_CITY_CONFIG_GET_ALL = "AGENT_CITY_CONFIG_GET_ALL";
export const AGENT_CITY_CONFIG_GET_ALL_LIST = "AGENT_CITY_CONFIG_GET_ALL_LIST";
export const AGENT_CITY_CONFIG_RELOAD = "AGENT_CITY_CONFIG_RELOAD";
export const AGENT_CITY_CONFIG_OPEN_DIALOG = "AGENT_CITY_CONFIG_OPEN_DIALOG";
export const AGENT_CITY_CONFIG_TRANSFER_LIST_OPEN_DIALOG =
  "AGENT_CITY_CONFIG_TRANSFER_LIST_OPEN_DIALOG";
export const AGENT_CITY_CONFIG_TRANSFER_LIST_DATA =
  "AGENT_CITY_CONFIG_TRANSFER_LIST_DATA";
export const AGENT_CITY_CONFIG_PAGE_NUMBER = "AGENT_CITY_CONFIG_PAGE_NUMBER";
export const AGENT_CITY_CONFIG_GET_BY_ID = "AGENT_CITY_CONFIG_GET_BY_ID";
export const AGENT_CITY_CONFIG_RESET_FORM = "AGENT_CITY_CONFIG_RESET_FORM";
export const AGENT_CITY_CONFIG_SEARCH_BODY = "AGENT_CITY_CONFIG_SEARCH_BODY";
export const AGENT_CITY_CONFIG_AGENT_ID = "AGENT_CITY_CONFIG_AGENT_ID";
export const AGENT_CITY_CONFIG_RELOAD_ADD_DELETE =
  "AGENT_CITY_CONFIG_RELOAD_ADD_DELETE";
export const AGENT_REQUEST_GET_ALL = "AGENT_REQUEST_GET_ALL";

export const DYNAMIC_AUTH_GET_ALL_ROLE = "DYNAMIC_AUTH_GET_ALL_ROLE";
export const DYNAMIC_AUTH_GET_ALL_STAFF_USERS =
  "DYNAMIC_AUTH_GET_ALL_STAFF_USERS";
export const DYNAMIC_AUTH_GET_ALL_ROLE_END_POINT =
  "DYNAMIC_AUTH_GET_ALL_ROLE_END_POINT";
export const DYNAMIC_AUTH_GET_ALL_ORDERII_END_POINT =
  "DYNAMIC_AUTH_GET_ALL_ORDERII_END_POINT";
export const DYNAMIC_AUTH_GET_ALL_LIST = "DYNAMIC_AUTH_GET_ALL_LIST";
export const DYNAMIC_AUTH_RELOAD = "DYNAMIC_AUTH_RELOAD";
export const DYNAMIC_AUTH_OPEN_DIALOG = "DYNAMIC_AUTH_OPEN_DIALOG";
export const DYNAMIC_AUTH_OPEN_STAFF_USERS_DIALOG =
  "DYNAMIC_AUTH_OPEN_STAFF_USERS_DIALOG";
export const DYNAMIC_AUTH_PAGE_NUMBER = "DYNAMIC_AUTH_PAGE_NUMBER";
export const DYNAMIC_AUTH_GET_BY_ID = "DYNAMIC_AUTH_GET_BY_ID";
export const DYNAMIC_AUTH_GET_BY_ID_STAFF_USERS =
  "DYNAMIC_AUTH_GET_BY_ID_STAFF_USERS";
export const DYNAMIC_AUTH_RESET_FORM = "DYNAMIC_AUTH_RESET_FORM";
export const DYNAMIC_AUTH_RESET_STAFF_USERS_FORM =
  "DYNAMIC_AUTH_RESET_STAFF_USERS_FORM";
export const DYNAMIC_AUTH_SEARCH_BODY = "DYNAMIC_AUTH_SEARCH_BODY";

export const OPEN_VENDORS_CATEGORY_DIALOG = "OPEN_VENDORS_CATEGORY_DIALOG";
export const OPEN_VENDORS_CATEGORIES_DIALOG = "OPEN_VENDORS_CATEGORIES_DIALOG";
export const GET_BY_ID_VENDORS_CATEGORY = "GET_BY_ID_VENDORS_CATEGORY";
export const RELOAD_VENDORS_CATEGORY = "RELOAD_VENDORS_CATEGORY";
export const GET_VENDORS_CATEGORY_LIST = "GET_VENDORS_CATEGORY_LIST";
export const GET_ALL_VENDORS_CATEGORY = "GET_ALL_VENDORS_CATEGORY";
export const SAVE_VENDORS_CATEGORY_PAGE_NO = "SAVE_VENDORS_CATEGORY_PAGE_NO";
export const SAVE_VENDORS_CATEGORY_SEARCH_BODY =
  "SAVE_VENDORS_CATEGORY_SEARCH_BODY";
export const RESET_VENDORS_CATEGORY_FORM = "RESET_VENDORS_CATEGORY_FORM";

export const OPEN_DEALS_DIALOG = "OPEN_DEALS_DIALOG";
export const GET_BY_ID_DEALS = "GET_BY_ID_DEALS";
export const RELOAD_DEALS = "RELOAD_DEALS";
export const GET_DEALS_LIST = "GET_DEALS_LIST";
export const GET_ALL_DEALS = "GET_ALL_DEALS";
export const SAVE_DEALS_PAGE_NO = "SAVE_DEALS_PAGE_NO";
export const SAVE_DEALS_SEARCH_BODY = "SAVE_DEALS_SEARCH_BODY";
export const RESET_DEALS_FORM = "RESET_DEALS_FORM";

export const UPLOAD_ATTACHMENTS = "UPLOAD_ATTACHMENTS";
export const RELOAD_ATTACHMENTS = "RELOAD_ATTACHMENTS";

export const PROMOTED_ITEMS_RESET_FORM = "PROMOTED_ITEMS_RESET_FORM";
export const PROMOTED_ITEMS_GET_BY_ID = "PROMOTED_ITEMS_GET_BY_ID";
export const PROMOTED_ITEMS_PAGE_NUMBER = "PROMOTED_ITEMS_PAGE_NUMBER";
export const PROMOTED_ITEMS_SEARCH_BODY = "PROMOTED_ITEMS_SEARCH_BODY";
export const PROMOTED_ITEMS_OPEN_DIALOG = "PROMOTED_ITEMS_OPEN_DIALOG";
export const PROMOTED_ITEMS_RELOAD = "PROMOTED_ITEMS_RELOAD";
export const PROMOTED_ITEMS_GET_ALL = "PROMOTED_ITEMS_GET_ALL";
export const PROMOTED_ITEMS_GET_ALL_LIST = "PROMOTED_ITEMS_GET_ALL_LIST";

export const RELOAD_HOME_ITEMS = "RELOAD_HOME_ITEMS";
export const SAVE_SEARCH_BODY_HOME_ITEMS = "SAVE_SEARCH_BODY_HOME_ITEMS";
export const GET_ALL_HOME_ITEMS = "GET_ALL_HOME_ITEMS";
export const OPEN_DIALOG_HOME_ITEMS = "OPEN_DIALOG_HOME_ITEMS";

export const RATING_QUESTIONS_RESET_FORM = "RATING_QUESTIONS_RESET_FORM";
export const RATING_QUESTIONS_GET_BY_ID = "RATING_QUESTIONS_GET_BY_ID";
export const RATING_QUESTIONS_OPEN_DIALOG = "RATING_QUESTIONS_OPEN_DIALOG";
export const RATING_QUESTIONS_SEARCH_BODY = "RATING_QUESTIONS_SEARCH_BODY";
export const RATING_QUESTIONS_PAGE_NUMBER = "RATING_QUESTIONS_PAGE_NUMBER";
export const RATING_QUESTIONS_RELOAD = "RATING_QUESTIONS_RELOAD";
export const RATING_QUESTIONS_GET_ALL = "RATING_QUESTIONS_GET_ALL";
export const RATING_QUESTIONS_GET_ALL_LIST = "RATING_QUESTIONS_GET_ALL_LIST";
export const ORDER_RATING_GET_ALL = "ORDER_RATING_GET_ALL";
export const ORDER_RATING_SEARCH_BODY = "ORDER_RATING_SEARCH_BODY";
export const ORDER_RATING_PAGE_NUMBER = "ORDER_RATING_PAGE_NUMBER";
export const ORDER_RATING_RELOAD = "ORDER_RATING_RELOAD";

export const CONTACT_INFO_RESET_FORM = "CONTACT_INFO_RESET_FORM";
export const CONTACT_INFO_GET_BY_ID = "CONTACT_INFO_GET_BY_ID";
export const CONTACT_INFO_SEARCH_BODY = "CONTACT_INFO_SEARCH_BODY";
export const CONTACT_INFO_OPEN_DIALOG = "CONTACT_INFO_OPEN_DIALOG";
export const CONTACT_INFO_RELOAD = "CONTACT_INFO_RELOAD";
export const CONTACT_INFO_GET_ALL = "CONTACT_INFO_GET_ALL";

export const FAQ_RESET_FORM = "FAQ_RESET_FORM";
export const FAQ_GET_BY_ID = "FAQ_GET_BY_ID";
export const FAQ_PAGE_NUMBER = "FAQ_PAGE_NUMBER";
export const FAQ_SEARCH_BODY = "FAQ_SEARCH_BODY";
export const FAQ_OPEN_DIALOG = "FAQ_OPEN_DIALOG";
export const FAQ_RELOAD = "FAQ_RELOAD";
export const FAQ_GET_ALL = "FAQ_GET_ALL";
export const FAQ_GET_ALL_LIST = "FAQ_GET_ALL_LIST";

export const NOTIFICATION_RELOAD = "NOTIFICATION_RELOAD";
export const PERSONAL_NOTIFICATION_GET_ALL = "PERSONAL_NOTIFICATION_GET_ALL";
export const NOTIFICATION_GET_ALL = "NOTIFICATION_GET_ALL";
export const NOTIFICATION_GET_BY_ID = "NOTIFICATION_GET_BY_ID";
export const NOTIFY_ALL_DIALOG = "NOTIFY_ALL_DIALOG";
export const NOTIFY_SEGMENT_DIALOG = "NOTIFY_SEGMENT_DIALOG";
export const NOTIFY_BY_USER_ID_DIALOG = "NOTIFY_BY_USER_ID_DIALOG";
export const NOTIFICATION_SEARCH_BODY = "NOTIFICATION_SEARCH_BODY";
export const SEGMENT_NOTIFICATION_RESET_FORM =
  "SEGMENT_NOTIFICATION_RESET_FORM";
export const NOTIFY_ALL_NOTIFICATION_RESET_FORM =
  "NOTIFY_ALL_NOTIFICATION_RESET_FORM";
export const NOTIFY_BY_USER_ID_NOTIFICATION_RESET_FORM =
  "NOTIFY_BY_USER_ID_NOTIFICATION_RESET_FORM";

export const GET_EXCHANGE_RATE = "GET_EXCHANGE_RATE";

export const ONBOARDING_RESET_FORM = "ONBOARDING_RESET_FORM";
export const ONBOARDING_GET_BY_ID = "ONBOARDING_GET_BY_ID";
export const ONBOARDING_SEARCH_BODY = "ONBOARDING_SEARCH_BODY";
export const ONBOARDING_OPEN_DIALOG = "ONBOARDING_OPEN_DIALOG";
export const ONBOARDING_RELOAD = "ONBOARDING_RELOAD";
export const ONBOARDING_GET_ALL = "ONBOARDING_GET_ALL";

export const RELOAD_UNCOMPLETED_CART = "RELOAD_UNCOMPLETED_CART";
export const GET_ALL_UNCOMPLETED_CART = "GET_ALL_UNCOMPLETED_CART";
export const UNCOMPLETED_CART_PAGE_NUMBER = "UNCOMPLETED_CART_PAGE_NUMBER";
export const UNCOMPLETED_CART_SEARCH_BODY = "UNCOMPLETED_CART_SEARCH_BODY";

export const ADDITIONAL_FEES_RESET_FORM = "ADDITIONAL_FEES_RESET_FORM";
export const ADDITIONAL_FEES_OPEN_DIALOG = "ADDITIONAL_FEES_OPEN_DIALOG";
export const ADDITIONAL_FEES_OPEN_FORM_DIALOG =
  "ADDITIONAL_FEES_OPEN_FORM_DIALOG";

export const SHIPMENTS_RESET_FORM = "SHIPMENTS_RESET_FORM";
export const SHIPMENTS_GET_BY_ID = "SHIPMENTS_GET_BY_ID";
export const SHIPMENTS_PAGE_NUMBER = "SHIPMENTS_PAGE_NUMBER";
export const SHIPMENTS_SEARCH_BODY = "SHIPMENTS_SEARCH_BODY";
export const SHIPMENTS_OPEN_DIALOG = "SHIPMENTS_OPEN_DIALOG";
export const SHIPMENTS_RELOAD = "SHIPMENTS_RELOAD";
export const SHIPMENTS_GET_ALL = "SHIPMENTS_GET_ALL";
export const ORDER_ITEM_EXIST_IN_SHIPMENT = "ORDER_ITEM_EXIST_IN_SHIPMENT";
export const PRINT_PACKING_LIST = "PRINT_PACKING_LIST";
export const PREPARING_SHIPMENTS = "PREPARING_SHIPMENTS";
export const SHIPMENTS_TRACKING_NUMBERS = "SHIPMENTS_TRACKING_NUMBERS";

export const PURCHASING_BILL_RESET_FORM = "PURCHASING_BILL_RESET_FORM";
export const PURCHASING_BILL_GET_BY_ID = "PURCHASING_BILL_GET_BY_ID";
export const PURCHASING_BILL_PAGE_NUMBER = "PURCHASING_BILL_PAGE_NUMBER";
export const PURCHASING_BILL_SEARCH_BODY = "PURCHASING_BILL_SEARCH_BODY";
export const PURCHASING_BILL_OPEN_DIALOG = "PURCHASING_BILL_OPEN_DIALOG";
export const PURCHASING_BILL_RELOAD = "PURCHASING_BILL_RELOAD";
export const PURCHASING_BILL_GET_ALL = "PURCHASING_BILL_GET_ALL";
export const PURCHASING_BILL_EXPORT_TO_CSV = "PURCHASING_BILL_EXPORT_TO_CSV";

export const OPEN_SHIPPING_OPTIONS_FORM_DIALOG =
  "OPEN_SHIPPING_OPTIONS_FORM_DIALOG";
export const OPEN_SHIPPING_OPTIONS_DIALOG = "OPEN_SHIPPING_OPTIONS_DIALOG";
export const GET_BY_ID_SHIPPING_OPTIONS = "GET_BY_ID_SHIPPING_OPTIONS";
export const RELOAD_SHIPPING_OPTIONS = "RELOAD_SHIPPING_OPTIONS";
export const GET_SHIPPING_OPTIONS_LIST = "GET_SHIPPING_OPTIONS_LIST";
export const GET_ALL_SHIPPING_OPTIONS = "GET_ALL_SHIPPING_OPTIONS";
export const SAVE_SHIPPING_OPTIONS_PAGE_NO = "SAVE_SHIPPING_OPTIONS_PAGE_NO";
export const SAVE_SHIPPING_OPTIONS_SEARCH_BODY =
  "SAVE_SHIPPING_OPTIONS_SEARCH_BODY";
export const RESET_SHIPPING_OPTIONS_FORM = "RESET_SHIPPING_OPTIONS_FORM";

export const GET_ALL_GO_IP = "GET_ALL_GO_IP";
export const GET_CREDIT_GO_IP = "GET_CREDIT_GO_IP";
export const RESET_FORM_GO_IP = "RESET_FORM_GO_IP";
export const OPEN_GO_IP_DIALOG = "OPEN_GO_IP_DIALOG";
export const RELOAD_GO_IP = "RELOAD_GO_IP";
export const SAVE_GO_IP_SAVE_NO = "SAVE_GO_IP_SAVE_NO";
export const SAVE_GO_IP_SEARCH_BODY = "SAVE_GO_IP_SEARCH_BODY";

export const OPEN_UNKNOWN_ITEMS_DIALOG = "OPEN_UNKNOWN_ITEMS_DIALOG";
export const GET_BY_ID_UNKNOWN_ITEMS = "GET_BY_ID_UNKNOWN_ITEMS";
export const RELOAD_UNKNOWN_ITEMS = "RELOAD_UNKNOWN_ITEMS";
export const GET_UNKNOWN_ITEMS_LIST = "GET_UNKNOWN_ITEMS_LIST";
export const GET_ALL_UNKNOWN_ITEMS = "GET_ALL_UNKNOWN_ITEMS";
export const SAVE_UNKNOWN_ITEMS_PAGE_NO = "SAVE_UNKNOWN_ITEMS_PAGE_NO";
export const SAVE_UNKNOWN_ITEMS_SEARCH_BODY = "SAVE_UNKNOWN_ITEMS_SEARCH_BODY";
export const RESET_UNKNOWN_ITEMS_FORM = "RESET_UNKNOWN_ITEMS_FORM";

export const PROHIBITED_ITEMS_GET_ALL = "PROHIBITED_ITEMS_GET_ALL";
export const PROHIBITED_ITEMS_GET_ALL_LIST = "PROHIBITED_ITEMS_GET_ALL_LIST";
export const PROHIBITED_ITEMS_RELOAD = "PROHIBITED_ITEMS_RELOAD";
export const PROHIBITED_ITEMS_OPEN_DIALOG = "PROHIBITED_ITEMS_OPEN_DIALOG";
export const PROHIBITED_ITEMS_PAGE_NUMBER = "PROHIBITED_ITEMS_PAGE_NUMBER";
export const PROHIBITED_ITEMS_GET_BY_ID = "PROHIBITED_ITEMS_GET_BY_ID";
export const PROHIBITED_ITEMS_RESET_FORM = "PROHIBITED_ITEMS_RESET_FORM";
export const PROHIBITED_ITEMS_SEARCH_BODY = "PROHIBITED_ITEMS_SEARCH_BODY";

export const GET_ALL_SCHEDULED_ACTION = "GET_ALL_SCHEDULED_ACTION";
export const GET_BY_ID_SCHEDULED_ACTION = "GET_BY_ID_SCHEDULED_ACTION";
export const RESET_FORM_SCHEDULED_ACTION = "RESET_FORM_SCHEDULED_ACTION";
export const OPEN_SCHEDULED_ACTION_DIALOG = "OPEN_SCHEDULED_ACTION_DIALOG";
export const RELOAD_SCHEDULED_ACTION = "RELOAD_SCHEDULED_ACTION";
export const SAVE_SCHEDULED_ACTION_SAVE_NO = "SAVE_SCHEDULED_ACTION_SAVE_NO";
export const SAVE_SCHEDULED_ACTION_SEARCH_BODY =
  "SAVE_SCHEDULED_ACTION_SEARCH_BODY";

export const GET_ALL_LOCKERS = "GET_ALL_LOCKERS";
export const GET_LOCKER_HISTORY = "GET_LOCKER_HISTORY";
export const GET_LOCKERS_LIST = "GET_LOCKERS_LIST";
export const GET_BY_ID_LOCKER = "GET_BY_ID_LOCKER";
export const RESET_FORM_LOCKER = "RESET_FORM_LOCKER";
export const OPEN_LOCKER_DIALOG = "OPEN_LOCKER_DIALOG";
export const OPEN_AVAILABLE_LOCKER_DIALOG = "OPEN_AVAILABLE_LOCKER_DIALOG";
export const OPEN_LOCKER_HISTORY_DIALOG = "OPEN_LOCKER_HISTORY_DIALOG";
export const RELOAD_LOCKER = "RELOAD_LOCKER";
export const SAVE_LOCKER_SAVE_NO = "SAVE_LOCKER_SAVE_NO";
export const SAVE_LOCKER_HISTORY_SAVE_NO = "SAVE_LOCKER_HISTORY_SAVE_NO";
export const SAVE_LOCKER_PAGE_SIZE = "SAVE_LOCKER_PAGE_SIZE";
export const SAVE_LOCKER_SEARCH_BODY = "SAVE_LOCKER_SEARCH_BODY";
export const SAVE_LOCKER_HISTORY_SEARCH_BODY =
  "SAVE_LOCKER_HISTORY_SEARCH_BODY";
export const IS_ADD_LOCKERS = "IS_ADD_LOCKERS";

export const RELOAD_REPORT = "RELOAD_REPORT";
export const GET_WALLET_AND_BANK_ACCOUNT = "GET_WALLET_AND_BANK_ACCOUNT";
export const SAVE_WALLET_AND_BANK_ACCOUNT_SEARCH_BODY =
  "SAVE_WALLET_AND_BANK_ACCOUNT_SEARCH_BODY";
export const SAVE_WALLET_AND_BANK_ACCOUNT_SAVE_NO =
  "SAVE_WALLET_AND_BANK_ACCOUNT_SAVE_NO";

export const GET_CUSTOMER_INFO_REPORT = "GET_CUSTOMER_INFO_REPORT";
export const SAVE_CUSTOMER_INFO_REPORT_SEARCH_BODY =
  "SAVE_CUSTOMER_INFO_REPORT_SEARCH_BODY";
export const SAVE_CUSTOMER_INFO_REPORT_SAVE_NO =
  "SAVE_CUSTOMER_INFO_REPORT_SAVE_NO";

export const GET_ITEMS_PURCHASED_REPORT = "GET_ITEMS_PURCHASED_REPORT";
export const SAVE_ITEMS_PURCHASED_REPORT_SEARCH_BODY =
  "SAVE_ITEMS_PURCHASED_REPORT_SEARCH_BODY";
export const SAVE_ITEMS_PURCHASED_REPORT_SAVE_NO =
  "SAVE_ITEMS_PURCHASED_REPORT_SAVE_NO";

export const GET_USER_BEHAVIOR_REPORT = "GET_USER_BEHAVIOR_REPORT";
export const SAVE_USER_BEHAVIOR_REPORT_SEARCH_BODY =
  "SAVE_USER_BEHAVIOR_REPORT_SEARCH_BODY";
export const SAVE_USER_BEHAVIOR_REPORT_SAVE_NO =
  "SAVE_USER_BEHAVIOR_REPORT_SAVE_NO";

export const GET_PRODUCT_ECONOMICS_REPORT = "GET_PRODUCT_ECONOMICS_REPORT";
export const SAVE_PRODUCT_ECONOMICS_REPORT_SEARCH_BODY =
  "SAVE_PRODUCT_ECONOMICS_REPORT_SEARCH_BODY";
export const SAVE_PRODUCT_ECONOMICS_REPORT_SAVE_NO =
  "SAVE_PRODUCT_ECONOMICS_REPORT_SAVE_NO";

export const GET_PRODUCT_DURATIONS_REPORT = "GET_PRODUCT_DURATIONS_REPORT";
export const SAVE_PRODUCT_DURATIONS_REPORT_SEARCH_BODY =
  "SAVE_PRODUCT_DURATIONS_REPORT_SEARCH_BODY";
export const SAVE_PRODUCT_DURATIONS_REPORT_SAVE_NO =
  "SAVE_PRODUCT_DURATIONS_REPORT_SAVE_NO";

export const GET_CART_CREATION_REPORT = "GET_CART_CREATION_REPORT";
export const SAVE_CART_CREATION_REPORT_SEARCH_BODY =
  "SAVE_CART_CREATION_REPORT_SEARCH_BODY";
export const SAVE_CART_CREATION_REPORT_SAVE_NO =
  "SAVE_CART_CREATION_REPORT_SAVE_NO";

export const GET_TABULATED_INFORMATION_REPORT =
  "GET_TABULATED_INFORMATION_REPORT";
export const SAVE_TABULATED_INFORMATION_REPORT_SEARCH_BODY =
  "SAVE_TABULATED_INFORMATION_REPORT_SEARCH_BODY";
export const SAVE_TABULATED_INFORMATION_REPORT_SAVE_NO =
  "SAVE_TABULATED_INFORMATION_REPORT_SAVE_NO";

export const LOCAL_SHIPPING_COMPANY_RESET_FORM =
  "LOCAL_SHIPPING_COMPANY_RESET_FORM";
export const LOCAL_SHIPPING_COMPANY_GET_BY_ID =
  "LOCAL_SHIPPING_COMPANY_GET_BY_ID";
export const LOCAL_SHIPPING_COMPANY_PAGE_NUMBER =
  "LOCAL_SHIPPING_COMPANY_PAGE_NUMBER";
export const LOCAL_SHIPPING_COMPANY_SEARCH_BODY =
  "LOCAL_SHIPPING_COMPANY_SEARCH_BODY";
export const LOCAL_SHIPPING_COMPANY_OPEN_DIALOG =
  "LOCAL_SHIPPING_COMPANY_OPEN_DIALOG";
export const LOCAL_SHIPPING_COMPANY_RELOAD = "LOCAL_SHIPPING_COMPANY_RELOAD";
export const LOCAL_SHIPPING_COMPANY_GET_ALL = "LOCAL_SHIPPING_COMPANY_GET_ALL";
export const LOCAL_SHIPPING_COMPANY_GET_LIST =
  "LOCAL_SHIPPING_COMPANY_GET_LIST";

export const INTERNATIONAL_SHIPPING_COMPANY_RESET_FORM =
  "INTERNATIONAL_SHIPPING_COMPANY_RESET_FORM";
export const INTERNATIONAL_SHIPPING_COMPANY_GET_BY_ID =
  "INTERNATIONAL_SHIPPING_COMPANY_GET_BY_ID";
export const INTERNATIONAL_SHIPPING_COMPANY_PAGE_NUMBER =
  "INTERNATIONAL_SHIPPING_COMPANY_PAGE_NUMBER";
export const INTERNATIONAL_SHIPPING_COMPANY_SEARCH_BODY =
  "INTERNATIONAL_SHIPPING_COMPANY_SEARCH_BODY";
export const INTERNATIONAL_SHIPPING_COMPANY_OPEN_DIALOG =
  "INTERNATIONAL_SHIPPING_COMPANY_OPEN_DIALOG";
export const INTERNATIONAL_SHIPPING_COMPANY_RELOAD =
  "INTERNATIONAL_SHIPPING_COMPANY_RELOAD";
export const INTERNATIONAL_SHIPPING_COMPANY_GET_ALL =
  "INTERNATIONAL_SHIPPING_COMPANY_GET_ALL";
export const INTERNATIONAL_SHIPPING_COMPANY_GET_LIST =
  "INTERNATIONAL_SHIPPING_COMPANY_GET_LIST";

export const GET_ALL_HI_EXPRESS_ORDERS = "GET_ALL_HI_EXPRESS_ORDERS";
export const GET_HI_EXPRESS_ORDER_DETAILS = "GET_HI_EXPRESSORDER_DETAILS";
export const LOADING_HI_EXPRESS_ORDER = "LOADING_HI_EXPRESS_ORDER";
export const SAVE_EXPRESS_ORDER_PAGE_NO = "SAVE_EXPRESS_ORDER_PAGE_NO";
export const SAVE_EXPRESS_ORDER_SEARCH_BODY = "SAVE_EXPRESS_ORDER_SEARCH_BODY";

export const OPEN_MERCHANT_APPLY_FORM_DIALOG =
  "OPEN_MERCHANT_APPLY_FORM_DIALOG";
export const GET_BY_ID_MERCHANT_APPLY_FORM = "GET_BY_ID_MERCHANT_APPLY_FORM";
export const RELOAD_MERCHANT_APPLY_FORM = "RELOAD_MERCHANT_APPLY_FORM";
export const GET_MERCHANT_APPLY_FORM_LIST = "GET_MERCHANT_APPLY_FORM_LIST";
export const GET_ALL_MERCHANT_APPLY_FORM = "GET_ALL_MERCHANT_APPLY_FORM";
export const SAVE_MERCHANT_APPLY_FORM_PAGE_NO =
  "SAVE_MERCHANT_APPLY_FORM_PAGE_NO";
export const SAVE_MERCHANT_APPLY_FORM_SEARCH_BODY =
  "SAVE_MERCHANT_APPLY_FORM_SEARCH_BODY";
export const RESET_MERCHANT_APPLY_FORM_FORM = "RESET_MERCHANT_APPLY_FORM_FORM";
