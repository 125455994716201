import React, { useState, useEffect, useRef } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  Tooltip,
  Icon,
  Button,
  Typography,
  Divider,
  LinearProgress,
  Stack,
  SpeedDial,
  Chip,
  Grid,
  CardHeader,
  SpeedDialIcon,
  SpeedDialAction,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import OrderItemStatusMenu from "./Status/Manu";
import ChangeStatusDialog from "./Status/ChangeStatusDialog";
import QrCode from "react-qr-code";
import { useReactToPrint } from "react-to-print";
import TimelineDialog from "./TimeLine/OrderItemTimeline";
import UpdateItemInfo from "./UpdateItem/UpdateItemInfo";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "src/actions/snackbarMessages";
import {
  getAllFilteredOrderItems,
  getAllOrderItems,
  saveItemsPageNum,
  saveSearchBody,
  setSelectedOrderItems,
  updateStatus,
} from "src/actions/orderItems";
import { RiSwapBoxFill } from "react-icons/ri";
import OrderItemsSearchFilter from "./SearchFilter";
import {
  DataGrid,
  GridActionsCellItem,
  GridPagination,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import CustomNoRowsOverlay from "src/components/CustomNoRowsOverlay";
import { BiEdit } from "react-icons/bi";
import { VENDORS_LOGO } from "src/utils/common";
import { useMemo } from "react";
import moment from "moment";
import { CustomDialog } from "src/components/CustomDialog";
import ShipmentsForm from "src/pages/cross/shipments/form";
import { getById, openDialog } from "src/actions/shipments";
import { FaBoxOpen, FaRegCopy } from "react-icons/fa";
import { SHIPMENTS_GET_BY_ID } from "src/actions/type";
import ExistInShipmentsForm from "src/pages/cross/shipments/form/existInShipments";
import { Helmet } from "react-helmet";
import theme from "src/theme";
import useCanShow from "src/components/CanShow";
import { MdHistory, MdViewInAr } from "react-icons/md";
import { VscNote } from "react-icons/vsc";
import { makeStyles } from "@mui/styles";
import { openNoteHistoryDialog } from "src/actions/orders";
import NoteHistoryDialog from "src/pages/cross/orders/noteHistory/noteHistory";

const useStyles = makeStyles((theme) => ({
  staticTooltipLabel: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
}));

export default function OrderItems() {
  const printRef = useRef(null);
  const dispatch = useDispatch();

  const classes = useStyles();

  const orderDetailsRole = useCanShow("22-03");
  const editOrderRole = useCanShow("23-02");
  const qrCodeRole = useCanShow("23-03");
  const timelineRole = useCanShow("23-10");
  const noteHistoryRole = useCanShow("22-16");

  const _UserInfo = useSelector((state) => state.users.userInfo);

  const orderItems = useSelector((state) => state.orderItems.data);
  const rowCount = useSelector((state) => state.orderItems.rowCount);
  const loading = useSelector((state) => state.orderItems.reload);
  const _searchBody = useSelector((state) => state.orderItems.searchBody);
  const saveItemsPageNO = useSelector(
    (state) => state.orderItems.saveItemsPageNum
  );
  const shipments = useSelector((state) => state.shipments);
  const orderNoteHistoryDialog = useSelector(
    (state) => state.orders.orderHistoryDialog
  );

  let navigate = useNavigate();

  const [page, setPage] = useState(saveItemsPageNO ? saveItemsPageNO : 0);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openEditPriceDialog, setOpenEditPriceDialog] = useState(false);
  const [openTimelineDialog, setOpenTimelineDialog] = useState(false);
  const [openQrDialog, setOpenQrDialog] = useState(false);
  const [openChangeItemStatusDialog, setOpenChangeItemStatusDialog] =
    useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [selectedItem, setSelectedItem] = useState({
    id: null,
    vendorTrackingNumber: "",
    vendorInvoiceNumber: "",
  });
  const [statusID, setStatusID] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedDateRows, setSelectedDateRows] = useState([]);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [OpenMultiSelectDialog, setOpenMultiSelectDialog] = useState(false);
  const [itemStatus, setItemStatus] = useState("");

  let speedDialActions = [
    {
      icon: <RiSwapBoxFill size={30} color="white" />,
      content: (
        <Stack
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          width={200}
        >
          <Typography variant="h6">Add To Existed Shipment</Typography>
        </Stack>
      ),
      onClick: () => {
        dispatch({
          type: SHIPMENTS_GET_BY_ID,
          payload: {
            shippingNumber: "",
            itemsCount: selectedRowsData.length,
            region: "",
            orderItems: selectedRowsData,
          },
        });
        dispatch(openDialog("updateShipment"));
      },
    },
    {
      icon: <FaBoxOpen size={30} color="white" />,
      content: (
        <Stack
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          width={200}
        >
          <Typography variant="h6">Generate New Shipment</Typography>
        </Stack>
      ),
      onClick: () => {
        dispatch({
          type: SHIPMENTS_GET_BY_ID,
          payload: {
            shippingNumber: "",
            itemsCount: selectedRowsData.length,
            region: "",
            orderItems: selectedRowsData,
          },
        });
        dispatch(openDialog("orderItems"));
      },
    },
    // {
    //   icon: <BiEdit size={30} />,
    //   content: (
    //     <Stack
    //       display={"flex"}
    //       flexDirection={"column"}
    //       alignItems={"center"}
    //       width={200}
    //     >
    //       <Typography variant="h6">Update Order Items</Typography>
    //     </Stack>
    //   ),
    //   onClick: () => {
    //     setOpenMultiSelectDialog(true);
    //   },
    // },
  ];

  speedDialActions = speedDialActions.filter((action) =>
    _UserInfo.roleOEndpoint.some((value) =>
      ["23-11", "59-03"].includes(value.Code)
    )
      ? action
      : null
  );

  useEffect(() => {
    if (_UserInfo?.roleOEndpoint?.filter((api) => api.Code === "23-01")[0]) {
      dispatch(
        getAllOrderItems(
          page + 1,
          _searchBody
            ? {
                ..._searchBody,
                region:
                  _UserInfo.id === "7d059cd8-f0fb-45db-ae1b-832e80a047da" ||
                  _UserInfo.id === "f441e0d3-abf1-4a67-a57e-4b5f5d4f1106"
                    ? "US"
                    : _searchBody.region,
              }
            : {}
        )
      );
    } else if (
      _UserInfo?.roleOEndpoint?.filter((api) => api.Code === "23-20")[0]
    ) {
      dispatch(getAllFilteredOrderItems(page + 1, _searchBody));
    }
    setSelectedRows([]);
    setSelectedDateRows([]);
    dispatch(setSelectedOrderItems([]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_searchBody, page, refresh]);

  useEffect(() => {
    dispatch(saveSearchBody(_searchBody));
  }, [_searchBody, dispatch]);

  useEffect(() => {
    dispatch(saveItemsPageNum(page));
  }, [dispatch, page]);

  const handleLimitChange = (newPageSize) => {
    dispatch(saveSearchBody({ ..._searchBody, pageSize: newPageSize }));
    setPage(0);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const changeStatusOnPrint = () => {
    if (statusID !== "") {
      dispatch(
        updateStatus({
          ids: [statusID],
          itemStatus: 3,
        })
      ).then((res) => {
        setStatusID("");
        setOpenQrDialog(false);
        setRefresh(!refresh);
      });
    }
    setIsSearch(!isSearch);
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    copyStyles: true,
    pageStyle: "@page { margin: 10px }",
  });

  const orderItemToolbar = () => {
    return (
      <Box>
        {orderNoteHistoryDialog && <NoteHistoryDialog />}
        {shipments.openDialog && (
          <ShipmentsForm
            handleClose={() => {
              setSelectedRows([]);
              setSelectedDateRows([]);
              dispatch(setSelectedOrderItems([]));
            }}
          />
        )}
        {shipments.OrderItemsExistInShipments.dialog && (
          <ExistInShipmentsForm />
        )}
        {selectedRows.length > 0 && (
          <Box
            sx={{
              zIndex: 1000,
            }}
          >
            <SpeedDial
              ariaLabel="SpeedDial tooltip example"
              sx={{ position: "absolute", bottom: 200, right: 16 }}
              icon={<SpeedDialIcon />}
              open={selectedRows.length > 0 ? true : false}
            >
              {speedDialActions.map((action, i) => (
                <SpeedDialAction
                  key={i}
                  icon={action.icon}
                  tooltipTitle={action.content}
                  tooltipOpen
                  onClick={action.onClick}
                  classes={classes}
                  sx={{
                    "& .MuiSpeedDialAction-fab": {
                      width: "50px",
                      height: "50px",
                      backgroundColor: theme.palette.primary.main,
                    },
                  }}
                />
              ))}
              <SpeedDialAction
                icon={<BiEdit size={30} color="white" />}
                classes={classes}
                tooltipTitle={
                  <Stack
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    width={200}
                  >
                    <Typography variant="h6">Update Order Items</Typography>
                  </Stack>
                }
                tooltipOpen
                onClick={() => {
                  setOpenMultiSelectDialog(true);
                }}
                sx={{
                  "& .MuiSpeedDialAction-fab": {
                    width: "50px",
                    height: "50px",
                    backgroundColor: theme.palette.primary.main,
                  },
                }}
              />
            </SpeedDial>
          </Box>
        )}
        <TimelineDialog
          open={openTimelineDialog}
          handleClose={() => setOpenTimelineDialog(false)}
          orderItemId={selectedItem.id}
        />
        <CustomDialog
          isOpen={openQrDialog}
          handleClose={() => {
            setOpenQrDialog(false);
          }}
          title="Generate QR"
          maxWidth="sm"
        >
          <Box ref={printRef} sx={{ textAlign: "center", marginRight: 1 }}>
            {selectedItem.orderiiNumber && (
              <QrCode value={selectedItem.orderiiNumber.toString()} size="80" />
            )}
            <Typography style={{ marginTop: "5px" }}>
              {selectedItem.orderiiNumber}
            </Typography>
            <Typography style={{ marginTop: "5px" }}>Orderii</Typography>
          </Box>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => {
              handlePrint();
              changeStatusOnPrint();
            }}
            sx={{ marginTop: 2 }}
          >
            Print and Change Status to (In Region Warehouse)
          </Button>
          <Button
            fullWidth
            variant="contained"
            color="warning"
            onClick={() => {
              handlePrint();
              setOpenQrDialog(false);
            }}
            sx={{ marginTop: 2 }}
          >
            Print
          </Button>
        </CustomDialog>
        <UpdateItemInfo
          isOpen={openEditDialog}
          isOpenPurchasePrice={openEditPriceDialog}
          isOpenMultiItem={OpenMultiSelectDialog}
          selectedRows={selectedRows}
          selectedRowsData={selectedRowsData}
          selectedDateRows={selectedDateRows}
          handleClose={() => {
            setOpenEditDialog(false);
            setOpenEditPriceDialog(false);
            setOpenMultiSelectDialog(false);
            setSelectedRows([]);
            setSelectedDateRows([]);
            dispatch(setSelectedOrderItems([]));
          }}
          title="Update Order Item"
          onSuccess={(msg) => {
            // dispatch(showSuccessSnackbar(msg));
            setRefresh(!refresh);
          }}
          onFail={(msg) => {
            dispatch(showErrorSnackbar(msg));
          }}
          itemId={selectedItem.id}
          itemStatus={itemStatus}
          type="orderItems"
        />
        <Stack direction="row" spacing={2}>
          <ChangeStatusDialog
            open={openChangeItemStatusDialog}
            handleClose={() => {
              setOpenChangeItemStatusDialog(false);
            }}
            selectedRows={selectedRows}
            onSuccess={(msg) => {
              dispatch(showSuccessSnackbar(msg));
              setRefresh(!refresh);
              setOpenChangeItemStatusDialog(false);
              setSelectedRows([]);
              setSelectedDateRows([]);
              dispatch(setSelectedOrderItems([]));
            }}
            onFail={(msg) => {
              dispatch(showErrorSnackbar(msg));
              setRefresh(!refresh);
              setOpenChangeItemStatusDialog(false);
              setSelectedRows([]);
              setSelectedDateRows([]);
              dispatch(setSelectedOrderItems([]));
              dispatch(setSelectedOrderItems([]));
            }}
          />
        </Stack>
      </Box>
    );
  };

  const Vendor = useMemo(
    () =>
      ({ params }) => {
        return (
          <Stack alignItems="center">
            <img
              alt="vendor logo"
              src={
                params.vendorRegion === "TR"
                  ? "/static/images/flags/turkey.png"
                  : params.vendorRegion === "US"
                  ? "/static/images/flags/usa.png"
                  : params.vendorRegion === "EU"
                  ? "/static/images/flags/eu.png"
                  : params.vendorRegion === "AEU"
                  ? "/static/images/flags/aeu.png"
                  : params.vendorRegion === "WSM"
                  ? "/static/images/flags/wsm.png"
                  : "/static/images/flags/aeu.png"
              }
              width={40}
              height={30}
            />
            <img
              alt="vendor logo"
              src={
                params.vendorLogo
                  ? params.vendorLogo
                  : VENDORS_LOGO.filter((vendor) =>
                      vendor.name == params.vendorName ? vendor.path : null
                    )[0]?.path
              }
              width={95}
            />
            {params.shippingOptionNameEn != null ? (
              <Chip
                variant="outlined"
                size="small"
                label={params.shippingOptionNameEn}
                color={
                  params.shippingOptionNameEn === "Sea"
                    ? "info"
                    : params.shippingOptionNameEn === "Express"
                    ? "warning"
                    : "success"
                }
              />
            ) : null}
          </Stack>
        );
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orderItems]
  );

  const Item = useMemo(
    () =>
      ({ item }) => {
        const handleStatusSuccess = (newStatus) => {
          setRefresh(!refresh);
          setSelectedItem(item);
          if (newStatus === 3) setOpenQrDialog(true);
        };

        return (
          <Stack
            direction="column"
            spacing={1}
            justifyContent="center"
            alignItems="start"
            width="100%"
          >
            <Box sx={{ display: "flex", overflow: "visible" }}>
              <Box
                component="img"
                sx={{ height: 100, width: 110, objectFit: "contain" }}
                alt="itemImage"
                src={item.imageUrl}
              />
              <Box
                sx={{ marginLeft: "10px", width: "220px", overflow: "visible" }}
              >
                <Tooltip title={<Typography>{item.itemName}</Typography>}>
                  <a
                    rel="noreferrer"
                    href={
                      item.productUrl.includes("shein")
                        ? item.productUrl.replace(/(https:\/\/\s*)m/g, "$1ar")
                        : item.productUrl
                    }
                    target="_blank"
                  >
                    <Typography variant="body1" noWrap>
                      {item.itemName}
                    </Typography>
                  </a>
                </Tooltip>
                <Typography variant="body2">Price: {item.price} $</Typography>
                <Typography variant="body2">Weight: {item.weight}</Typography>
                {item.purchasePrice ? (
                  <Typography variant="body2" color="primary">
                    Purchase Price: {item.purchasePrice} $
                  </Typography>
                ) : null}
                <Typography variant="body2">
                  <Tooltip
                    title={
                      <Typography>
                        {item.size ? `Size: ${item.size}` : ""}
                      </Typography>
                    }
                  >
                    <span>{item.size ? `Size: ${item.size}` : ""}</span>
                  </Tooltip>
                </Typography>
                <Typography variant="body2">
                  <Tooltip
                    title={
                      <Typography>
                        {item.color ? `Color: ${item.color}` : ""}
                      </Typography>
                    }
                  >
                    <span>{item.color ? `Color: ${item.color}` : ""}</span>
                  </Tooltip>
                </Typography>
                <Typography variant="body2" style={{ wordWrap: "break-word" }}>
                  <Tooltip
                    title={
                      <Typography>
                        {item.style ? `${item.style}` : ""}
                      </Typography>
                    }
                  >
                    <span>{item.style}</span>
                  </Tooltip>
                </Typography>
                {item.hasPurchaseError && (
                  <Chip
                    variant="outlined"
                    label="Purchase Error!"
                    color="error"
                  />
                )}
              </Box>
            </Box>
            <Stack
              direction="column"
              spacing={1}
              justifyContent="center"
              alignItems="start"
            >
              <OrderItemStatusMenu
                orderItemData={item}
                orderItemId={item.id}
                status={item.itemStatusString}
                onSuccess={handleStatusSuccess}
              />
              {item.cancelNote && (
                <Tooltip
                  title={item.cancelNote}
                  aria-label="cancel"
                  placement="bottom-start"
                >
                  <Typography variant="body1" noWrap>
                    {item.cancelNote}
                  </Typography>
                </Tooltip>
              )}
            </Stack>
            <Box display="flex" width="100%">
              {item.internalStatuses.map((status, index) => (
                <Chip
                  key={index}
                  variant="outlined"
                  size="small"
                  label={status.statusName}
                  color="primary"
                  sx={{ margin: "2px" }}
                />
              ))}
            </Box>
          </Stack>
        );
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orderItems]
  );

  const columns = [
    {
      field: "sequence",
      headerName: "#",
      headerAlign: "center",
      width: 2,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "orderiiNumber",
      headerName: "Orderii Number",
      headerAlign: "center",
      flex: 0.3,
      sortable: false,
      disableColumnMenu: true,
      renderHeader: (params) => (
        <Typography
          sx={{
            whiteSpace: "pre-wrap",
            fontSize: "12px",
            fontWeight: "500",
            textAlign: "center",
          }}
        >
          Orderii Number
        </Typography>
      ),
      renderCell: (params) => (
        <Stack direction="column" spacing={1} alignItems="center">
          <Typography variant="body">{params.row?.orderiiNumber}</Typography>
          {params.row?.isDamaged ? (
            <Chip
              size="small"
              label="Damaged"
              color="error"
              variant="outlined"
              sx={{ fontSize: "10px" }}
            />
          ) : null}
          {params.row?.isDangerousGoods ? (
            <Chip
              size="small"
              label="Dangerous Goods"
              color="error"
              variant="outlined"
              sx={{ fontSize: "9px" }}
            />
          ) : null}
        </Stack>
      ),
    },
    {
      field: "createdAt",
      headerName: "Order Date",
      headerAlign: "center",
      flex: 0.3,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Stack direction="column" spacing={1}>
          <Typography variant="body">
            {moment(params.row?.createdAt).format("YYYY-MM-DD")}
          </Typography>
          <Typography variant="caption">
            {moment(params.row?.createdAt).format("hh:mm:ss a")}
          </Typography>
        </Stack>
      ),
    },
    {
      field: "vendors",
      headerName: "Vendors",
      headerAlign: "center",
      width: 130,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => <Vendor params={params.row} />,
      valueGetter: (params) =>
        params.row.vendorName +
        " | " +
        params.row.vendorRegion +
        " | " +
        params.row.shippingOptionNameEn,
    },
    {
      field: "item",
      headerName: "Item Details",
      headerAlign: "center",
      width: 340,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => <Item item={params.row} />,
      valueGetter: (params) =>
        params.row.itemName +
        " | " +
        params.row.itemStatusString +
        " | " +
        "Weight: " +
        params.row.weight,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      headerAlign: "center",
      flex: 0.2,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Typography
          color={params.row.quantity > 1 ? "#ff0000" : ""}
          sx={{ fontWeight: params.row.quantity > 1 ? "bold" : null }}
        >
          {params.row?.quantity}
        </Typography>
      ),
    },
    {
      field: "subTotal",
      headerName: "Subtotal",
      headerAlign: "center",
      flex: 0.2,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => <Typography>{params.row.subTotal} $</Typography>,
    },
    {
      field: "localPrice",
      headerName: "Local Price",
      headerAlign: "center",
      flex: 0.3,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Typography>
          {params.row.localPrice ? params.row.localPrice : "----"}
        </Typography>
      ),
    },
    {
      field: "vendorTrackingNumber",
      headerName: "Tracking Number",
      headerAlign: "center",
      flex: 0.5,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Typography sx={{ whiteSpace: "pre-wrap", lineBreak: "anywhere" }}>
          {params.row.vendorTrackingNumber
            ? params.row.vendorTrackingNumber
            : "----"}
        </Typography>
      ),
    },
    {
      field: "shippingNumber",
      headerName: "Shipment",
      headerAlign: "center",
      flex: 0.8,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Stack alignItems="center">
          <Stack
            direction="row"
            spacing={0}
            justifyContent="center"
            alignItems="center"
          >
            {params.row.shippingNumber?.shippingNumber ? (
              <IconButton
                onClick={(e) => {
                  navigator.clipboard.writeText(
                    params.row.shippingNumber?.shippingNumber
                  );
                  dispatch(showSuccessSnackbar("Order Number Copied"));
                }}
              >
                <FaRegCopy />
                <span style={{ whiteSpace: "break-spaces", color: "#4C5C76" }}>
                  {params.row.number}
                </span>
              </IconButton>
            ) : null}
            <Button
              disabled={!params.row.shippingNumber?.shippingNumber}
              onClick={(e) => {
                navigator.clipboard.writeText(
                  params.row.shippingNumber?.shippingNumber
                );
                dispatch(
                  showSuccessSnackbar("Shipment number copied to clipboard")
                );
                dispatch(getById(params.row.shippingNumber?.id)).then(() => {
                  dispatch(openDialog("updateShipment"));
                });
              }}
              sx={{ whiteSpace: "pre-wrap", lineBreak: "anywhere" }}
            >
              {params.row.shippingNumber
                ? params.row.shippingNumber?.shippingNumber
                : "----"}{" "}
            </Button>
          </Stack>
          {params.row.internationalShippingCompanyDto && (
            <Chip
              variant="outlined"
              color="primary"
              size="small"
              label={params.row.internationalShippingCompanyDto.name}
            />
          )}
        </Stack>
      ),
      valueGetter: (params) => params.row.shippingNumber?.shippingNumber,
    },
    {
      field: "isCod",
      headerName: "COD",
      headerAlign: "center",
      flex: 0.3,
      sortable: false,
      disableColumnMenu: true,
      renderHeader: (params) => (
        <Typography
          sx={{
            whiteSpace: "pre-wrap",
            fontSize: "12px",
            fontWeight: "500",
            textAlign: "center",
          }}
        >
          COD
        </Typography>
      ),
      renderCell: (params) => (
        <Chip
          variant="outlined"
          size="small"
          color={params.row.isCod ? "success" : "error"}
          label={params.row.isCod ? "Yes" : "No"}
        />
      ),
    },
    {
      field: "lockerNumber",
      headerName: "Locker Number",
      headerAlign: "center",
      flex: 0.3,
      sortable: false,
      disableColumnMenu: true,
      renderHeader: (params) => (
        <Typography
          sx={{
            whiteSpace: "pre-wrap",
            fontSize: "12px",
            fontWeight: "500",
            textAlign: "center",
          }}
        >
          Locker Number
        </Typography>
      ),
      renderCell: (params) => (
        <Stack spacing={0.5}>
          {/* <Typography>
            {params.row.lockerNumber
              ? "Old: " + params.row.lockerNumber
              : "----"}
          </Typography> */}
          <Typography>
            {params.row.locker ? params.row.locker.number : "----"}
          </Typography>
        </Stack>
      ),
      valueGetter: (params) => params.row.locker?.number,
    },
    {
      field: "notes",
      headerName: "Notes",
      headerAlign: "center",
      flex: 0.3,
      sortable: false,
      disableColumnMenu: true,
      renderHeader: (params) => (
        <Typography
          sx={{
            whiteSpace: "pre-wrap",
            fontSize: "12px",
            fontWeight: "500",
            textAlign: "center",
          }}
        >
          Notes
        </Typography>
      ),
      renderCell: (params) =>
        params.row.note && (
          <Stack
            direction="row"
            spacing={0.5}
            justifyContent="center"
            alignItems="center"
          >
            <Tooltip
              title={<Typography variant="h5">{params.row.note}</Typography>}
              followCursor
            >
              <Typography variant="body" color="green">
                <VscNote size={30} />
              </Typography>
            </Tooltip>
          </Stack>
        ),
      valueGetter: (params) => params.row.note,
    },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Icon color="primary">visibility</Icon>}
          label="Order Details"
          hidden={!orderDetailsRole}
          showInMenu
          onClick={(e) => {
            navigate(`/app/order/${params.row.orderId}`);
          }}
        />,
        <GridActionsCellItem
          icon={<Icon color="secondary">edit</Icon>}
          label="Edit Order Item"
          hidden={!editOrderRole}
          showInMenu
          onClick={(e) => {
            setOpenEditDialog(true);
            setSelectedItem(params.row);
            setItemStatus(params.row.itemStatusString);
          }}
        />,
        <GridActionsCellItem
          icon={<MdHistory size={25} />}
          label="Note History"
          hidden={!noteHistoryRole}
          showInMenu
          onClick={(e) => {
            dispatch(
              openNoteHistoryDialog({
                id: params.row.id,
                type: "orderItem",
              })
            );
          }}
        />,
        <GridActionsCellItem
          icon={<Icon>qr_code</Icon>}
          label="Qr Code"
          hidden={!qrCodeRole}
          showInMenu
          onClick={(e) => {
            setSelectedItem(params.row);
            setOpenQrDialog(true);
            setStatusID(params.row.id);
          }}
        />,
        <GridActionsCellItem
          icon={<Icon color="warning">timeline</Icon>}
          label="Timeline"
          hidden={!timelineRole}
          showInMenu
          onClick={(e) => {
            setSelectedItem(params.row);
            setOpenTimelineDialog(true);
          }}
        />,
      ],
    },
  ];

  function CustomToolbar() {
    return (
      _UserInfo.roles.includes("Export") && (
        <GridToolbarContainer>
          <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
        </GridToolbarContainer>
      )
    );
  }

  function CustomFooter() {
    return (
      <Stack
        direction="row"
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography color="primary" sx={{ marginX: 2 }}>
          Total Items Quantity :{" "}
          {orderItems.reduce((total, item) => total + item.quantity, 0)}
        </Typography>
        <GridPagination />
      </Stack>
    );
  }

  const memoizedDataGrid = useMemo(
    () => (
      <DataGrid
        sx={{
          ".MuiDataGrid-cell": {
            justifyContent: "center",
            overflow: "visible",
          },
          ".highlight": {
            bgcolor: "#bdbdbd",
            "&:hover": {
              bgcolor: "darkgrey",
            },
          },
        }}
        getRowClassName={(params) => {
          return params.row.itemStatus == 9 || params.row.itemStatus == 8
            ? "highlight"
            : "";
        }}
        rows={
          orderItems?.map((row, index) => {
            return { ...row, sequence: index + 1 };
          }) || []
        }
        columns={columns}
        rowHeight={200}
        disableSelectionOnClick
        checkboxSelection
        onSelectionModelChange={(selection) => {
          setSelectedRows(selection);
          const selectedPricesArray = selection.map(
            (selectedId) =>
              orderItems.find((row) => row.id === selectedId)?.itemId
          );
          const selectedRowsData = selection.map((selectedId) =>
            orderItems.find((row) => row.id === selectedId)
          );
          setSelectedDateRows(selectedPricesArray);
          setSelectedRowsData(selectedRowsData);
        }}
        isRowSelectable={(params) =>
          (params.row.itemStatus !== 9) & (params.row.itemStatus !== 8)
        }
        selectionModel={selectedRows}
        pagination
        autoHeight
        onPageChange={(newPage) => handlePageChange(newPage)}
        pageSize={_searchBody.pageSize}
        onPageSizeChange={(newPageSize) => handleLimitChange(newPageSize)}
        page={page}
        rowCount={rowCount ? rowCount : 0}
        paginationMode="server"
        rowsPerPageOptions={[10, 25, 50, 75, 100]}
        components={{
          LoadingOverlay: LinearProgress,
          NoRowsOverlay: CustomNoRowsOverlay,
          Toolbar: CustomToolbar,
          Footer: CustomFooter,
        }}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orderItems, _searchBody, page, rowCount, _UserInfo, selectedRows, loading]
  );

  return (
    <>
      <Helmet>
        <title>Order Items | Orderii</title>
      </Helmet>
      <Box sx={{ minWidth: { md: "100%", xs: 1800 } }}>
        <Grid container>
          <Grid item xs={12} sx={{ p: 2 }}>
            <Card>
              <CardHeader
                title={
                  <Typography variant="h4" component="div">
                    Order Items
                  </Typography>
                }
                avatar={<MdViewInAr size={20} />}
                action={orderItemToolbar()}
              />
            </Card>
          </Grid>
          <Grid item xs={12} sx={{ p: 2, paddingTop: 0 }}>
            <Card>
              <Stack
                direction="row"
                spacing={1}
                // sx={{ marginBottom: 2, alignItems: "center" }}
              >
                <OrderItemsSearchFilter
                  reload={loading}
                  pageNo={(newPage) => setPage(newPage)}
                />
              </Stack>
              <Divider />
              <PerfectScrollbar>
                <Box>
                  <Box>{loading && <LinearProgress />}</Box>
                  {memoizedDataGrid}
                  <Box>
                    {(orderItems.length > 0) & loading ? (
                      <LinearProgress />
                    ) : null}
                  </Box>
                </Box>
              </PerfectScrollbar>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
