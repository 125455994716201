import { TextField, Grid, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CustomDialog } from "src/components/CustomDialog";
import { LoadingButton } from "@mui/lab";
import { Controller, useForm } from "react-hook-form";
import React, { useEffect } from "react";
import { showErrorSnackbar } from "src/actions/snackbarMessages";
import { openDialog, resetForm, update } from "src/actions/merchantApplyForm";

const MerchantApplyForm = () => {
  const dispatch = useDispatch();

  const _MerchantApplyForm = useSelector((state) => state.merchantApplyForm);
  const loading = useSelector((state) => state.merchantApplyForm.reload);
  const searchBody = useSelector((state) => state.merchantApplyForm.searchBody);
  const pageNo = useSelector((state) => state.merchantApplyForm.pageNo);

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues: _MerchantApplyForm.form,
  });

  const handleDialog = () => {
    dispatch(openDialog());
  };

  useEffect(() => {
    reset({ ..._MerchantApplyForm.form });
  }, [reset, _MerchantApplyForm.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
  }, [dispatch]);

  const onSubmit = (data) => {
    dispatch(update(data.id, data, pageNo + 1, searchBody))
      .then((x) => {
        reset({ ..._MerchantApplyForm.form });
        dispatch(resetForm());
        handleDialog();
      })
      .catch(() => dispatch(showErrorSnackbar("Network error")));
  };

  return (
    <CustomDialog
      title={"Merchant Apply Form"}
      isOpen={_MerchantApplyForm.openDialog}
      keepMounted
      handleClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="sm"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Controller
              name="merchantName"
              control={control}
              render={({ field }) => (
                <TextField
                  size="small"
                  label="Merchan tName"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="merchantPhoneNumber"
              control={control}
              render={({ field }) => (
                <TextField
                  type="number"
                  size="small"
                  label="Merchant Phone Number"
                  fullWidth
                  {...field}
                  onChange={(e) => {
                    const value = e.target.value;
                    field.onChange(
                      value.startsWith("0") ? value.slice(1) : value
                    );
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="businessName"
              control={control}
              render={({ field }) => (
                <TextField
                  size="small"
                  label="Business Name"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="businessPhoneNumber"
              control={control}
              render={({ field }) => (
                <TextField
                  size="small"
                  type="number"
                  label="Business Phone Number"
                  fullWidth
                  {...field}
                  onChange={(e) => {
                    const value = e.target.value;
                    field.onChange(
                      value.startsWith("0") ? value.slice(1) : value
                    );
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="facebookUrl"
              control={control}
              render={({ field }) => (
                <TextField
                  size="small"
                  label="Facebook Url"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="instagramUrl"
              control={control}
              render={({ field }) => (
                <TextField
                  size="small"
                  label="Instagram Url"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Stack
              sx={{ marginTop: 2 }}
              direction="row"
              spacing={2}
              justifyContent="end"
              alignItems="center"
            >
              <LoadingButton
                variant="contained"
                type="submit"
                color="primary"
                loading={loading}
                disabled={!isDirty}
              >
                Save
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  );
};
export default MerchantApplyForm;
