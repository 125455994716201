import {
  GET_ALL_HI_EXPRESS_ORDERS,
  GET_HI_EXPRESS_ORDER_DETAILS,
  LOADING_HI_EXPRESS_ORDER,
  SAVE_EXPRESS_ORDER_PAGE_NO,
  SAVE_EXPRESS_ORDER_SEARCH_BODY,
} from "../actions/type";

const initialState = {
  reload: false,
  openDialog: false,
  pageNo: 0,
  data: {
    data: [],
    rowCount: 0,
    pageCount: 0,
  },
  searchBody: {
    orderNumber: "",
    phoneNumber: "",
    pageSize: 15,
  },
  getById: {
    order: {},
    items: [],
  },
};

function hiExpressReducer(hiExpress = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_HI_EXPRESS_ORDERS:
      hiExpress.data.data = payload.data;
      hiExpress.data.pageCount = payload.pageCount;
      hiExpress.data.rowCount = payload.rowCount;
      return hiExpress;

    case GET_HI_EXPRESS_ORDER_DETAILS:
      hiExpress.getById = {
        ...payload,
        items: payload.orderItems ?? payload.giftOrderItems,
      };
      return hiExpress;

    case SAVE_EXPRESS_ORDER_PAGE_NO:
      return { ...hiExpress, pageNo: payload };

    case LOADING_HI_EXPRESS_ORDER:
      return { ...hiExpress, reload: !hiExpress.reload };

    case SAVE_EXPRESS_ORDER_SEARCH_BODY:
      return { ...hiExpress, searchBody: payload };

    default:
      return hiExpress;
  }
}

export default hiExpressReducer;
