import axios from "../axios";

class HiExpressService {
  getAllHiExpressOrders(pageNo, searchBody) {
    let params = "";
    if (searchBody) {
      Object.keys(searchBody).forEach((key) => {
        params += `${key}=${searchBody[key] ?? ""}&`;
      });
    }
    return axios.get(`/hiexpress/getall/${pageNo}?${params}`);
  }

  getHiExpressOrderDetails(id) {
    return axios.get(`/hiexpress/${id}`);
  }

  sentOrderItemsToHiExpress(data) {
    return axios.put(`/hiexpress/sendorderitemstohiexpress`, data);
  }

  sentGiftOrdersToHiExpress(ordrId) {
    return axios.put(`/hiexpress/sendgiftordertohiexpress/${ordrId}`);
  }
}

export default new HiExpressService();
