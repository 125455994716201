import * as React from "react";
import { Card, Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import Details from "./details";
import OrderDetailsTable from "./table";
import { getHiExpressOrderDetails } from "src/actions/hiExpress";

export default function HeExpressOrderDetails() {
  const dispatch = useDispatch();

  const orderId = window.location.pathname.replace("/app/delivery/order/", "");

  const [refresh, setRefresh] = useState(false);
  const [selectedRows, setSelectRows] = useState([]);

  useEffect(() => {
    dispatch(getHiExpressOrderDetails(orderId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);
  return (
    <Card>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Details
              refresh={() => setRefresh(!refresh)}
              onSelectedRows={(rows) => setSelectRows(rows)}
              selectedRows={selectedRows}
              onSuccess={() => {
                setSelectRows([]);
              }}
            />
          </Grid>
          <OrderDetailsTable
            refresh={() => setRefresh(!refresh)}
            onSelectedRows={(rows) => setSelectRows(rows)}
            selectedRows={selectedRows}
            onSuccess={() => {
              setSelectRows([]);
            }}
          />
        </Grid>
      </Grid>
    </Card>
  );
}
