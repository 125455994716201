import axiosService from "src/api/axiosService";

class MerchantApplyFormService {
  create(data) {
    return axiosService.post(`/api/merchants`, data);
  }
  getById(id) {
    return axiosService.get(`/api/merchants/getbyid/${id}`);
  }
  getAll(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] !== null && data[key] !== undefined && data[key] !== "") {
          params += `${key}=${data[key] ?? ""}&`;
        }
      });
    }
    return axiosService.get(`api/merchants/${pageNo}?${params}`);
  }
  update(id, data) {
    return axiosService.put(`/api/merchants/${id}`, data);
  }
  delete(id) {
    return axiosService.delete(`/api/merchants/${id}`);
  }
  updateStatus(id, status) {
    return axiosService.put(`api/merchants/${id}/${status}`);
  }
}

export default new MerchantApplyFormService();
