import {
  GET_ALL_ORDER_ITEMS,
  SAVE_SEARCH_BODY_ITEMS,
  SAVE_ITEMS_PAGE_NUM,
  RELOAD_ORDER_ITEM,
  SET_SELECTED_ORDER_ITEMS,
} from "./type";

import OrderItemsDataService from "../api/services/orderItems.service";
import helper from "src/utils/helper";
import { getDetails } from "./orders";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "./snackbarMessages";
import { openDialog, orderItemsExistInShipments } from "src/actions/shipments";

export const getAllOrderItems = (pageNo, data) => async (dispatch) => {
  try {
    dispatch(reloadOrderItem());
    const res = await OrderItemsDataService.getAll(pageNo, data);
    dispatch({
      type: GET_ALL_ORDER_ITEMS,
      payload: res.data.data,
    });
    dispatch(reloadOrderItem());
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(reloadOrderItem());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500) {
      dispatch(
        showErrorSnackbar(
          err.response.data.message ?? err.response.data.Message
        )
      );
    } else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getAllFilteredOrderItems = (pageNo, data) => async (dispatch) => {
  try {
    dispatch(reloadOrderItem());
    const res = await OrderItemsDataService.getAllFilteredOnly(pageNo, data);
    dispatch({
      type: GET_ALL_ORDER_ITEMS,
      payload: res.data.data,
    });
    dispatch(reloadOrderItem());
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(reloadOrderItem());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500) {
      dispatch(
        showErrorSnackbar(
          err.response.data.message ?? err.response.data.Message
        )
      );
    } else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const reloadOrderItem = () => (dispatch) => {
  dispatch({
    type: RELOAD_ORDER_ITEM,
  });
};

export const getOrderItemByQr = (pageNo, data) => async (dispatch) => {
  try {
    const res = await OrderItemsDataService.getAll(pageNo, data);
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const generateShippingNumber =
  (data, pageNo, searchBody, apiType) => async (dispatch) => {
    try {
      const res = await OrderItemsDataService.generateShippingNumber(data);
      dispatch(showSuccessSnackbar("Shipping number generated successfully"));
      if (apiType === "orderItemPage") {
        dispatch(getAllOrderItems(pageNo, searchBody));
      } else if (apiType === "orderItemPageFiltered") {
        dispatch(getAllFilteredOrderItems(pageNo, searchBody));
      }
      dispatch(openDialog(""));
      return Promise.resolve(res.data);
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400) {
        dispatch(showErrorSnackbar(err.response.data.message));
        dispatch(
          orderItemsExistInShipments(err.response.data.data.existShipments)
        );
      } else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const updateShipmentItemsStatus = (data) => async (dispatch) => {
  try {
    const res = await OrderItemsDataService.updateShipmentItemsStatus(data);
    dispatch(showSuccessSnackbar("تم التحديث بنجاح"));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const changeNewStatus = (data, orderId) => async (dispatch) => {
  try {
    const res = await OrderItemsDataService.changeNewStatus(data);
    dispatch(getDetails(orderId, true));
    dispatch(showSuccessSnackbar("Status changed successfully"));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updateStatus = (data) => async (dispatch) => {
  try {
    const res = await OrderItemsDataService.updateStatus(data);
    dispatch(showSuccessSnackbar("Status changed successfully"));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400) {
      dispatch(showErrorSnackbar(err.response.data.message));
      if (
        err.response.data.message ===
        "The selected order items is already in another shipments "
      ) {
        dispatch(orderItemsExistInShipments(err.response.data.data));
      }
    } else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updateItemInternalStatus = (data) => async (dispatch) => {
  try {
    const res = await OrderItemsDataService.updateItemInternalStatus(data);
    dispatch(showSuccessSnackbar("Status changed successfully"));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400) {
      dispatch(showErrorSnackbar(err.response.data.message));
    } else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updateTrackingNumber = (data) => async (dispatch) => {
  try {
    const res = await OrderItemsDataService.updateTrackingNumber(data);
    dispatch(showSuccessSnackbar("Updated successfully"));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updateInternationalTrackingNumber = (data) => async (dispatch) => {
  try {
    const res = await OrderItemsDataService.updateInternationalTrackingNumber(
      data
    );
    dispatch(showSuccessSnackbar("Updated successfully"));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const wrongTackingNumber = (data) => async (dispatch) => {
  try {
    const res = await OrderItemsDataService.wrongTackingNumber(data);
    dispatch(showSuccessSnackbar("Updated successfully"));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updateInvoiceNumber = (data) => async (dispatch) => {
  try {
    const res = await OrderItemsDataService.updateInvoiceNumber(data);
    dispatch(showSuccessSnackbar("Updated successfully"));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const deliveryDateUpdate = (data) => async (dispatch) => {
  try {
    const res = await OrderItemsDataService.deliveryDateUpdate(data);
    dispatch(showSuccessSnackbar("Updated successfully"));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updateNote = (data) => async (dispatch) => {
  try {
    const res = await OrderItemsDataService.updateNote(data);
    dispatch(showSuccessSnackbar("Updated successfully"));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const saveSearchBody = (data) => (dispatch) => {
  dispatch({
    type: SAVE_SEARCH_BODY_ITEMS,
    payload: data,
  });
};

export const saveItemsPageNum = (data) => (dispatch) => {
  dispatch({
    type: SAVE_ITEMS_PAGE_NUM,
    payload: data,
  });
};

export const getOrderItemHistory = (orderItemId) => async (dispatch) => {
  try {
    const res = await OrderItemsDataService.getHistory(orderItemId);
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const purchasePrice = (id, data) => async (dispatch) => {
  try {
    const res = await OrderItemsDataService.purchasePrice(id, data);
    dispatch(showSuccessSnackbar("Price updated successfully"));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const trackingInfo = (data) => async (dispatch) => {
  try {
    const res = await OrderItemsDataService.trackingInfo(data);
    dispatch(showSuccessSnackbar("updated successfully"));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const hasPurchaseError = (data) => async (dispatch) => {
  try {
    const res = await OrderItemsDataService.hasPurchaseError(data);
    dispatch(showSuccessSnackbar("updated successfully"));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const toggleIsDangerousGoods = (data) => async (dispatch) => {
  try {
    const res = await OrderItemsDataService.toggleIsDangerousGoods(data);
    dispatch(showSuccessSnackbar("updated successfully"));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const toggleIsDamaged = (data) => async (dispatch) => {
  try {
    const res = await OrderItemsDataService.toggleIsDamaged(data);
    dispatch(showSuccessSnackbar("updated successfully"));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updateLocalShippingCompany = (id, data) => async (dispatch) => {
  try {
    const res = await OrderItemsDataService.updateLocalShippingCompany(
      id,
      data
    );
    dispatch(showSuccessSnackbar("updated successfully"));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const setSelectedOrderItems = (data) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_ORDER_ITEMS,
    payload: data,
  });
};
