import {
  OPEN_MERCHANT_APPLY_FORM_DIALOG,
  GET_BY_ID_MERCHANT_APPLY_FORM,
  GET_ALL_MERCHANT_APPLY_FORM,
  SAVE_MERCHANT_APPLY_FORM_PAGE_NO,
  RESET_MERCHANT_APPLY_FORM_FORM,
  RELOAD_MERCHANT_APPLY_FORM,
  SAVE_MERCHANT_APPLY_FORM_SEARCH_BODY,
} from "../actions/type";

const initialState = {
  reload: false,
  openDialog: false,
  pageNo: 0,
  form: {
    id: null,
    merchantName: "",
    merchantPhoneNumber: "",
    busnessName: "",
    busnessPhoneNumber: "",
    facebookUrl: "",
    instagramUrl: "",
  },
  data: {
    data: [],
    rowCount: 0,
    pageCount: 0,
  },
  searchBody: {
    pageSize: 15,
    merchantName: "",
    merchantPhoneNumber: "",
    businessPhoneNumber: "",
    businessName: "",
  },
  getById: {},
};

function merchantApplyFormReducer(merchantApplyForm = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_MERCHANT_APPLY_FORM:
      merchantApplyForm.data.data = payload.data;
      merchantApplyForm.data.pageCount = payload.pageCount;
      merchantApplyForm.data.rowCount = payload.rowCount;
      return merchantApplyForm;

    case GET_BY_ID_MERCHANT_APPLY_FORM:
      return {
        ...merchantApplyForm,
        form: payload,
      };

    case OPEN_MERCHANT_APPLY_FORM_DIALOG:
      return {
        ...merchantApplyForm,
        openDialog: !merchantApplyForm.openDialog,
      };

    case SAVE_MERCHANT_APPLY_FORM_PAGE_NO:
      return { ...merchantApplyForm, pageNo: payload };

    case RELOAD_MERCHANT_APPLY_FORM:
      return { ...merchantApplyForm, reload: !merchantApplyForm.reload };

    case SAVE_MERCHANT_APPLY_FORM_SEARCH_BODY:
      return { ...merchantApplyForm, searchBody: payload };

    case RESET_MERCHANT_APPLY_FORM_FORM:
      merchantApplyForm.form = initialState.form;
      return merchantApplyForm;

    default:
      return merchantApplyForm;
  }
}

export default merchantApplyFormReducer;
