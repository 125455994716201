import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Card,
  TextField,
  Tooltip,
  Grid,
  CardContent,
  LinearProgress,
  InputAdornment,
  Autocomplete,
  Typography,
  Stack,
} from "@mui/material";
import { DeleteForever } from "@mui/icons-material";
import { AiOutlineSearch } from "react-icons/ai";
import IconButton from "@mui/material/IconButton";
import CustomNoRowsOverlay from "src/components/CustomNoRowsOverlay";
import { CustomDialog } from "src/components/CustomDialog";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import {
  deleteMerchantApplyForm,
  getAll,
  getById,
  resetForm,
  savePageNum,
  saveSearchBody,
} from "src/actions/merchantApplyForm";
import moment from "moment";
import useCanShow from "src/components/CanShow";

const MerchantApplyFormTable = () => {
  const dispatch = useDispatch();

  const updateRole = useCanShow("67-04");
  const deleteRole = useCanShow("67-06");

  const merchantApplyFormData = useSelector(
    (state) => state.merchantApplyForm.data
  );
  const merchantApplyForm = useSelector((state) => state.merchantApplyForm);
  const loading = useSelector((state) => state.merchantApplyForm.reload);
  const searchBody = useSelector((state) => state.merchantApplyForm.searchBody);
  const _PageNo = useSelector((state) => state.merchantApplyForm.pageNo);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [page, setPage] = useState(_PageNo ?? 0);
  const [search, setSearch] = useState({
    merchantName: searchBody.merchantName,
    merchantPhoneNumber: searchBody.merchantPhoneNumber,
    businessPhoneNumber: searchBody.businessPhoneNumber,
    businessName: searchBody.businessName,
  });

  useEffect(() => {
    dispatch(getAll(page + 1, searchBody));
  }, [page, dispatch, searchBody]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
    dispatch(savePageNum(newPage));
  };

  function deleteSelectedMerchantApply(id) {
    dispatch(deleteMerchantApplyForm(id, page + 1, searchBody)).then((x) => {
      dispatch(resetForm());
      setOpenDeleteDialog(false);
    });
  }

  const columns = [
    {
      field: "#",
      headerName: "#",
      flex: 0.3,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "merchantName",
      headerName: "Merchant Name",
      flex: 1,
      renderCell: (params) => (
        <Tooltip
          title={
            <Typography variant="h5">{params.row.merchantName}</Typography>
          }
        >
          <div
            style={{
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
            }}
          >
            {params.row.merchantName}
          </div>
        </Tooltip>
      ),
    },
    {
      field: "merchantPhoneNumber",
      headerName: "Merchant Phone Number",
      flex: 1,
    },
    {
      field: "businessName",
      headerName: "Business Name",
      flex: 1,
      renderCell: (params) => (
        <Tooltip
          title={
            <Typography variant="h5">{params.row.businessName}</Typography>
          }
        >
          <div
            style={{
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
            }}
          >
            {params.row.businessName}
          </div>
        </Tooltip>
      ),
    },
    {
      field: "businessPhoneNumber",
      headerName: "Business Phone Number",
      flex: 1,
    },
    {
      field: "facebookUrl",
      headerName: "Facebook Url",
      flex: 1,
      renderCell: (params) => (
        <Tooltip
          title={<Typography variant="h5">{params.row.facebookUrl}</Typography>}
        >
          <div
            style={{
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
              cursor: "pointer",
            }}
            onClick={() => window.open(params.row.facebookUrl, "_blank")}
          >
            {params.row.facebookUrl}
          </div>
        </Tooltip>
      ),
    },
    {
      field: "instagramUrl",
      headerName: "Instagram Url",
      flex: 1,
      renderCell: (params) => (
        <Tooltip
          title={
            <Typography variant="h5">{params.row.instagramUrl}</Typography>
          }
        >
          <div
            style={{
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
              cursor: "pointer",
            }}
            onClick={() => window.open(params.row.instagramUrl, "_blank")}
          >
            {params.row.instagramUrl}
          </div>
        </Tooltip>
      ),
    },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   flex: 1,
    //   renderCell: (params) => (
    //     <UnknownItemsStatusMenu
    //       id={params.row.id}
    //       status={params.row.statusName}
    //       statusNumber={params.row.status}
    //       onSuccess={() => {}}
    //     />
    //   ),
    // },
    {
      field: "createdAt",
      headerName: "Date",
      flex: 0.5,
      renderCell: (params) => (
        <Stack>
          <Typography variant="body">
            {moment(params.row.createdAt).format("YYYY-MM-DD")}
          </Typography>
          <Typography variant="caption">
            {moment(params.row.createdAt).format("hh:mm a")}
          </Typography>
        </Stack>
      ),
    },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon color="primary" />}
          label="Edit"
          hidden={updateRole ? false : true}
          showInMenu
          onClick={(e) => {
            dispatch(getById(params.row.id, true));
          }}
        />,
        <GridActionsCellItem
          icon={<DeleteForever color="error" />}
          label="Delete"
          hidden={deleteRole ? false : true}
          showInMenu
          onClick={(e) => {
            dispatch(getById(params.row.id, false)).then((x) =>
              setOpenDeleteDialog(true)
            );
          }}
        />,
      ],
    },
  ];

  return (
    <Card>
      <CardContent>
        {openDeleteDialog && (
          <CustomDialog
            isOpen={openDeleteDialog}
            handleClose={() => {
              setOpenDeleteDialog(false);
              dispatch(resetForm());
            }}
            isActiveDelete
            isRequierdActions
            title="Delete confirmation"
            maxWidth="sm"
            onDelete={() =>
              deleteSelectedMerchantApply(merchantApplyForm.form.id)
            }
            selectedObject={`${merchantApplyForm.form.merchantName}`}
          ></CustomDialog>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12} md={2}>
            <TextField
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AiOutlineSearch />
                  </InputAdornment>
                ),
              }}
              placeholder="Search Merchant Name"
              sx={{ marginRight: 1 }}
              variant="outlined"
              value={search.merchantName}
              onChange={(e) => {
                setSearch({
                  ...search,
                  merchantName: e.target.value,
                });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(
                    saveSearchBody({
                      ...searchBody,
                      merchantName: search.merchantName,
                    })
                  );
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AiOutlineSearch />
                  </InputAdornment>
                ),
              }}
              placeholder="Search Phone Number"
              sx={{ marginRight: 1 }}
              variant="outlined"
              value={search.merchantPhoneNumber}
              onChange={(e) => {
                const value = e.target.value;
                setSearch({
                  ...search,
                  merchantPhoneNumber: value.startsWith("0")
                    ? value.slice(1)
                    : value,
                });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(
                    saveSearchBody({
                      ...searchBody,
                      merchantPhoneNumber: search.merchantPhoneNumber,
                    })
                  );
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AiOutlineSearch />
                  </InputAdornment>
                ),
              }}
              placeholder="Search Buniness Name"
              sx={{ marginRight: 1 }}
              variant="outlined"
              value={search.businessName}
              onChange={(e) => {
                setSearch({
                  ...search,
                  businessName: e.target.value,
                });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(
                    saveSearchBody({
                      ...searchBody,
                      businessName: search.businessName,
                    })
                  );
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AiOutlineSearch />
                  </InputAdornment>
                ),
              }}
              placeholder="Search Business Phone Number"
              sx={{ marginRight: 1 }}
              variant="outlined"
              value={search.businessPhoneNumber}
              onChange={(e) => {
                const value = e.target.value;
                setSearch({
                  ...search,
                  businessPhoneNumber: value.startsWith("0")
                    ? value.slice(1)
                    : value,
                });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(
                    saveSearchBody({
                      ...searchBody,
                      businessPhoneNumber: search.businessPhoneNumber,
                    })
                  );
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Tooltip title="Search">
              <IconButton
                onClick={() => {
                  setPage(0);
                  dispatch(savePageNum(0));
                  dispatch(
                    saveSearchBody({
                      ...searchBody,
                      ...search,
                    })
                  );
                }}
              >
                <AiOutlineSearch />
              </IconButton>
            </Tooltip>
            <Tooltip title="Clear filter">
              <IconButton
                onClick={() => {
                  setPage(0);
                  dispatch(savePageNum(0));
                  setSearch({
                    pageSize: 15,
                    merchantName: "",
                    merchantPhoneNumber: "",
                    businessPhoneNumber: "",
                    businessName: "",
                  });
                  dispatch(
                    saveSearchBody({
                      ...searchBody,
                      pageSize: 15,
                      merchantName: "",
                      merchantPhoneNumber: "",
                      businessPhoneNumber: "",
                      businessName: "",
                    })
                  );
                }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <PerfectScrollbar>
              <Box sx={{ height: { md: "calc(100vh - 270px)", xs: 1000 } }}>
                <DataGrid
                  sx={{
                    "& .css-204u17-MuiDataGrid-main": {
                      padding: "15px",
                    },
                    "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                      padding: "15px",
                    },
                  }}
                  columns={columns}
                  rows={merchantApplyFormData?.data}
                  loading={loading}
                  disableSelectionOnClick
                  rowHeight={100}
                  pagination
                  paginationMode="server"
                  rowCount={merchantApplyFormData.rowCount}
                  pageSize={searchBody.pageSize}
                  page={page}
                  onPageChange={(newPage) => handlePageChange(newPage)}
                  onPageSizeChange={(newPageSize) =>
                    dispatch(
                      saveSearchBody({
                        ...searchBody,
                        pageSize: newPageSize,
                      })
                    )
                  }
                  rowsPerPageOptions={[5, 15, 25, 75, 100]}
                  components={{
                    LoadingOverlay: LinearProgress,
                    NoRowsOverlay: CustomNoRowsOverlay,
                  }}
                />
              </Box>
            </PerfectScrollbar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default MerchantApplyFormTable;
