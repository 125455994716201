import { Helmet } from "react-helmet";
import { Box } from "@mui/material";
import { Card, CardHeader, Typography } from "@mui/material";
import { TbTruckDelivery } from "react-icons/tb";

const HiExpressHeader = () => {
  return (
    <>
      <Helmet>
        <title>Hi Express Orders | Orderii</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Card>
          <CardHeader
            title={
              <Typography variant="h4" component="div">
                Hi Express Orders
              </Typography>
            }
            avatar={<TbTruckDelivery size={20} />}
          />
        </Card>
      </Box>
    </>
  );
};

export default HiExpressHeader;
