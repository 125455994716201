import * as React from "react";
import { Box } from "@mui/system";
import { Card, Divider, Grid, Tooltip, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { Stack } from "@mui/material";
import { Icon } from "@mui/material";
import moment from "moment";

export default function Details(props) {
  const order = useSelector((state) => state.hiExpress.getById);
  return (
    <Box>
      {order.items?.length !== 0 ? (
        <Stack direction="column">
          <Card sx={{ padding: 2 }}>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Stack direction="row" spacing={1} sx={{ py: 0.5 }}>
                    <Typography variant="h4">
                      {order?.order?.number ?? ""}
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={1} sx={{ py: 0.5 }}>
                    <Typography variant="h4">Total amount:</Typography>
                    <Typography variant="h4">
                      {order?.orderValue ?? 0} IQD
                    </Typography>
                  </Stack>
                  {order?.order?.isCod ? (
                    <Box>
                      <Stack direction="row" spacing={1} sx={{ py: 0.5 }}>
                        <Typography variant="h5">Cash On Delivery:</Typography>
                        <Stack direction="column" spacing={1}>
                          {order?.order?.isCod ? (
                            <>
                              <Icon color="success">check_circle</Icon>
                            </>
                          ) : (
                            <Icon color="error">cancel</Icon>
                          )}
                        </Stack>
                      </Stack>
                    </Box>
                  ) : null}
                  <Stack direction="row" spacing={1} sx={{ py: 0.5 }}>
                    <Typography variant="h5">Date:</Typography>
                    <Typography variant="h5">
                      {moment(order?.order?.createdAt).format(
                        "YYYY-MM-DD / hh:mm a"
                      )}
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={1} sx={{ py: 0.5 }}>
                    <Typography variant="h5">Quantity:</Typography>
                    <Typography variant="h5">
                      {order?.order?.quantity ?? 0}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack direction="row" spacing={1} sx={{ py: 0.5 }}>
                    <Typography variant="h5">Destination:</Typography>
                    <Typography variant="h5">
                      {order?.order?.destination}
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={1} sx={{ py: 0.5 }}>
                    <Typography variant="h5">Customer name:</Typography>
                    <Typography variant="h5">
                      {order.order?.user ? order.order?.user.name : ""}
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={1} sx={{ py: 0.5 }}>
                    <Typography variant="h5">Customer phone number:</Typography>
                    <Typography variant="h5">
                      {order.order?.user ? order.order?.user.phoneNumber : ""}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </Stack>
      ) : null}
    </Box>
  );
}
