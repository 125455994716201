import * as React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Box } from "@mui/system";
import { Tooltip, Typography, LinearProgress, Chip } from "@mui/material";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CustomNoRowsOverlay from "src/components/CustomNoRowsOverlay";
import { VENDORS_LOGO } from "src/utils/common";
import CustomSpinner from "src/components/CustomSpinner";
import OrderItemStatusMenu from "src/pages/cross/orderItems/Status/Manu";
import { useState } from "react";

export default function OrderDetailsTable(props) {
  const order = useSelector((state) => state.hiExpress.getById);
  const reload = useSelector((state) => state.hiExpress.reload);

  const Vendor = ({ params }) => {
    return (
      <Stack>
        <img
          alt="vendor logo"
          src={
            params.vendorLogo
              ? params.vendorLogo
              : VENDORS_LOGO.filter((vendor) =>
                  vendor.name == params.vendorName ? vendor.path : null
                )[0]?.path
          }
          width={95}
        />
        {params.shippingOptionNameEn != null ? (
          <Chip
            variant="outlined"
            size="small"
            label={params.shippingOptionNameEn}
            color={
              params.shippingOptionNameEn === "Sea"
                ? "info"
                : params.shippingOptionNameEn === "Express"
                ? "warning"
                : "success"
            }
          />
        ) : null}
      </Stack>
    );
  };

  const Item = ({ item }) => {
    return (
      <Stack
        direction="column"
        spacing={1}
        justifyContent="center"
        alignItems="start"
        width="100%"
      >
        <Box
          sx={{ display: "flex", lineBreak: "anywhere", overflow: "visible" }}
        >
          <Box
            component="img"
            sx={{
              height: 100,
              width: 100,
              objectFit: "contain",
            }}
            alt="itemImage"
            src={item.imageUrl}
          />
          <Box sx={{ marginLeft: "10px", width: "220px" }}>
            <Tooltip title={<Typography>{item.name}</Typography>}>
              <a
                rel="noreferrer"
                href={
                  item.productUrl.includes("shein")
                    ? item.productUrl.replace(/(https:\/\/\s*)m/g, "$1ar")
                    : item.productUrl
                }
                target="_blank"
              >
                <Typography variant="body1" noWrap>
                  {item.name}
                </Typography>
              </a>
            </Tooltip>
            <Tooltip
              title={
                <Typography>
                  {item.addedToCartDate
                    ? `Cart Date: ${moment(item.addedToCartDate).format(
                        "yyyy-MM-DD / HH:mm"
                      )}`
                    : ""}
                </Typography>
              }
            >
              <Typography variant="body">
                {item.addedToCartDate
                  ? `Cart Date: ${moment(item.addedToCartDate).format(
                      "yyyy-MM-DD / HH:mm"
                    )}`
                  : ""}
              </Typography>
            </Tooltip>
            <Typography>Price: {item.price} $</Typography>
            {item.purchasePrice ? (
              <Typography color="primary">
                Purchase Price: {item.purchasePrice} $
              </Typography>
            ) : null}
            <Box>
              <Tooltip
                title={
                  <Typography>
                    {item.size ? `size: ${item.size}` : ""}
                  </Typography>
                }
              >
                <Typography variant="body1" noWrap>
                  {item.size ? `size: ${item.size}` : ""}
                </Typography>
              </Tooltip>
            </Box>
            <Tooltip
              title={
                <Typography>{item.color ? `${item.color}` : ""}</Typography>
              }
            >
              <Typography>
                {item.color ? `color: ${item.color}` : ""}
              </Typography>
            </Tooltip>
            {item.style ? (
              <Tooltip
                title={
                  <Typography>{item.style ? `${item.style}` : ""}</Typography>
                }
              >
                <Typography style={{ wordWrap: "break-word" }}>
                  {item.style}
                </Typography>
              </Tooltip>
            ) : null}
          </Box>
        </Box>
        <Stack
          direction="column"
          spacing={1}
          justifyContent="center"
          alignItems="start"
        >
          <OrderItemStatusMenu
            orderItemData={item}
            orderItemId={item.orderItemId}
            status={item.itemStatusString}
            onSuccess={(newStatus) => {
              props.refresh();
            }}
          />
          {item.cancelNote ? (
            <Tooltip
              title={item.cancelNote}
              aria-label="cancel"
              placement="bottom-start"
            >
              <Typography sx={{ lineBreak: "anywhere" }}>
                {item.cancelNote}
              </Typography>
            </Tooltip>
          ) : null}
        </Stack>
        <Box display="flex" width="100%">
          {item.internalStatuses?.map((status, index) => (
            <Chip
              key={index}
              variant="outlined"
              size="small"
              label={status.statusName}
              color="primary"
              sx={{ margin: "2px" }}
            />
          ))}
        </Box>
      </Stack>
    );
  };

  const columns = [
    {
      field: "sequence",
      headerName: "#",
      headerAlign: "center",
      flex: 0.1,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "orderiiNumber",
      headerName: "Orderii No.",
      headerAlign: "center",
      flex: 0.3,
      renderCell: (params) => (
        <Stack direction="column" spacing={1} alignItems="center">
          <Typography>{params.row?.orderiiNumber}</Typography>
          {params.row?.isDamaged ? (
            <Chip
              size="small"
              label="Damaged"
              color="error"
              variant="outlined"
              sx={{ fontSize: "10px" }}
            />
          ) : null}
          {params.row?.isDangerousGoods ? (
            <Chip
              size="small"
              label="Dangerous Goods"
              color="error"
              variant="outlined"
              sx={{ fontSize: "10px" }}
            />
          ) : null}
        </Stack>
      ),
    },
    {
      field: "vendors",
      headerName: "Vendor",
      headerAlign: "center",
      flex: 0.3,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => <Vendor params={params.row} />,
    },
    {
      field: "item",
      headerName: "Item Details",
      headerAlign: "center",
      width: 340,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => <Item item={params.row} />,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      headerAlign: "center",
      flex: 0.44,
      sortable: false,
      disableColumnMenu: true,
    },
  ];

  const GiftItem = ({ item }) => {
    return (
      <Stack spacing={0} sx={{ width: "400px" }}>
        <Stack
          direction="row"
          // sx={{
          //   lineBreak: "anywhere",
          // }}
        >
          <Box
            component="img"
            sx={{
              height: "150px",
              width: "150px",
              objectFit: "contain",
            }}
            alt="itemImage"
            src={
              item?.giftItem?.giftItemOptions?.find(
                (option) => option.attachments?.url
              )?.attachments?.url || item?.giftItem?.giftItemAttachments?.url
            }
          />
          <Box
            sx={{
              marginLeft: "10px",
              width: "250px",
              overflowWrap: "break-word",
              wordWrap: "break-word",
            }}
          >
            <Tooltip
              title={
                <Typography variant="h5">{item?.giftItem?.nameEn}</Typography>
              }
              followCursor
            >
              <Typography
                variant="h5"
                style={{ whiteSpace: "normal", my: 1, fontWeight: "bold" }}
              >
                {item?.giftItem?.nameEn}
              </Typography>
            </Tooltip>
            <Tooltip
              title={
                <Typography variant="h5">
                  {item?.giftItem?.descriptionEn}
                </Typography>
              }
              followCursor
            >
              <Typography
                variant="h5"
                sx={{
                  my: 1,
                }}
                noWrap
              >
                <strong>description</strong>: {item?.giftItem?.descriptionEn}
              </Typography>
            </Tooltip>
            <Typography sx={{ my: 0.5 }} color="green">
              Sell Price: {item?.unitPrice?.toLocaleString()} IQD
            </Typography>
            <Typography sx={{ my: 0.5 }} color="blue">
              Cost Price: {item?.costPrice?.toLocaleString()} IQD
            </Typography>
            {item?.giftItem?.giftItemOptions.map((option, index) => (
              <Typography color="green" variant="body1" sx={{ my: 0.5 }}>
                <span key={index}>
                  {option?.options?.nameEn} : {option?.nameEn}{" "}
                  {option?.priceDifference > 0
                    ? `(+ ${option?.priceDifference?.toLocaleString()}) IQD`
                    : ""}
                </span>
              </Typography>
            ))}
          </Box>
        </Stack>
        {item?.isCanceled ? (
          <Stack
            direction="row"
            spacing={1}
            justifyContent="center"
            alignItems="center"
          >
            <Chip
              variant="outlined"
              color="error"
              label={"Canceled"}
              size="small"
            />
            {item?.isMoneyRefunded ? (
              <Chip
                variant="outlined"
                color="success"
                label={"Refunded"}
                size="small"
              />
            ) : (
              <Chip
                variant="outlined"
                color="error"
                label={"Not Refunded"}
                size="small"
              />
            )}
          </Stack>
        ) : null}
      </Stack>
    );
  };

  var giftColumns = [
    {
      field: "sequence",
      headerName: "#",
      headerAlign: "center",
      flex: 0.1,
      sortable: false,
    },
    {
      field: "nameEn",
      headerName: "Name",
      headerAlign: "center",
      width: 400,
      renderCell: (params) => <GiftItem item={params.row} />,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      headerAlign: "center",
      flex: 0.8,
      renderCell: (params) => (
        <Typography
          color={params.row.quantity > 1 ? "#ff0000" : ""}
          sx={{ fontWeight: params.row.quantity > 1 ? "bold" : null }}
        >
          {params.row?.quantity}
        </Typography>
      ),
    },
    {
      field: "sku",
      headerName: "SKU",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => (
        <div style={{ wordBreak: "break-word", whiteSpace: "normal" }}>
          {params.row?.sku}
        </div>
      ),
    },
    {
      field: "giftItemCategories",
      headerName: "Categories",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => (
        <Stack justifyContent="center" alignItems="center" spacing={1}>
          {params.row.giftItem?.giftItemCategories?.map((category, index) => (
            <Typography noWrap key={index}>
              {category?.nameEn}
            </Typography>
          ))}
        </Stack>
      ),
    },
    {
      field: "giftBrand",
      headerName: "Brand",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => (
        <Stack alignItems="center" spacing={1}>
          <Typography variant="body">
            {params.row.giftItem?.giftBrand?.nameEn}
          </Typography>
          <Box
            component="img"
            src={params.row.giftItem?.giftBrand?.attachment?.url}
            alt=""
            style={{
              height: "100px",
              width: "100px",
              padding: "3px",
              objectFit: "contain",
              border: "2px solid",
              borderRadius: "10px",
            }}
          />
        </Stack>
      ),
    },
    {
      field: "giftSupplier",
      headerName: "Supplier",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => (
        <Typography variant="body">
          {params.row.giftItem?.giftSupplier?.nameEn}
        </Typography>
      ),
    },
    {
      field: "unitPrice",
      headerName: "Unit Price",
      headerAlign: "center",
      flex: 0.8,
      renderCell: (params) => params.row.unitPrice?.toLocaleString(),
    },
    {
      field: "totalPrice",
      headerName: "Total Price",
      headerAlign: "center",
      flex: 0.8,
      renderCell: (params) => (
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="body">
            {params.row.totalPrice?.toLocaleString()}
          </Typography>
        </Stack>
      ),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => (
        <Stack alignItems="center" spacing={1}>
          <Typography variant="body">
            {moment(params.row.createdAt).format("yyyy-MM-DD")}
          </Typography>
          <Typography variant="body">
            {moment(params.row.createdAt).format("HH:mm: a")}
          </Typography>
          {params.row.giftItem?.lockerNumber ? (
            <Typography variant="body" color="error">
              Locker: {params.row.giftItem?.lockerNumber}
            </Typography>
          ) : null}
        </Stack>
      ),
    },
  ];

  return (
    <PerfectScrollbar>
      {!order ? (
        <CustomSpinner />
      ) : (
        <Box sx={{ height: { md: "calc(100vh - 280px)", xs: 1000 } }}>
          <DataGrid
            sx={{
              ".MuiDataGrid-cell": {
                justifyContent: "center",
              },
              ".highlight": {
                bgcolor: "#bdbdbd",
                "&:hover": {
                  bgcolor: "darkgrey",
                },
              },
            }}
            rows={order.items.map((row, index) => {
              return { ...row, sequence: index + 1 };
            })}
            loading={reload}
            columns={order.order.isGift ? giftColumns : columns}
            rowHeight={220}
            disableSelectionOnClick
            checkboxSelection
            selectionModel={props.selectedRows}
            pagination
            rowsPerPageOptions={[100]}
            paginationMode="client"
            components={{
              LoadingOverlay: LinearProgress,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
          />
        </Box>
      )}
    </PerfectScrollbar>
  );
}
