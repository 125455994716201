import HiExpressService from "../api/services/hiExpress.service";
import helper from "src/utils/helper";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "./snackbarMessages";
import {
  GET_ALL_HI_EXPRESS_ORDERS,
  GET_HI_EXPRESS_ORDER_DETAILS,
  LOADING_HI_EXPRESS_ORDER,
  SAVE_EXPRESS_ORDER_PAGE_NO,
  SAVE_EXPRESS_ORDER_SEARCH_BODY,
} from "./type";
import { getDetails } from "./orders";
import { getOrderById } from "./gifts/orders";

export const reloadHiExpressOrder = () => (dispatch) => {
  dispatch({
    type: LOADING_HI_EXPRESS_ORDER,
  });
};

export const saveHiExpressSearchBody = (data) => (dispatch) => {
  dispatch({
    type: SAVE_EXPRESS_ORDER_SEARCH_BODY,
    payload: data,
  });
};

export const saveHiExpressPageNo = (data) => (dispatch) => {
  dispatch({
    type: SAVE_EXPRESS_ORDER_PAGE_NO,
    payload: data,
  });
};

export const getAllHiExpressOrders =
  (pageNo, searchBody) => async (dispatch) => {
    try {
      dispatch(reloadHiExpressOrder());
      const res = await HiExpressService.getAllHiExpressOrders(
        pageNo,
        searchBody
      );
      dispatch({
        type: GET_ALL_HI_EXPRESS_ORDERS,
        payload: res.data.data,
      });
      dispatch(reloadHiExpressOrder());
    } catch (err) {
      dispatch(reloadHiExpressOrder());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const getHiExpressOrderDetails =
  (id, justNew = false) =>
  async (dispatch) => {
    try {
      dispatch(reloadHiExpressOrder());
      const res = await HiExpressService.getHiExpressOrderDetails(id, justNew);
      dispatch({
        type: GET_HI_EXPRESS_ORDER_DETAILS,
        payload: res.data.data,
      });
      dispatch(reloadHiExpressOrder());
      return Promise.resolve(res.data);
    } catch (err) {
      dispatch(reloadHiExpressOrder());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const sentOrderItemsToHiExpress =
  (data, orderId) => async (dispatch) => {
    try {
      const res = await HiExpressService.sentOrderItemsToHiExpress(data);
      dispatch(getDetails(orderId, true));
      dispatch(showSuccessSnackbar("Order created successfully"));
      return Promise.resolve(res.data);
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const sentGiftOrdersToHiExpress = (orderId) => async (dispatch) => {
  try {
    const res = await HiExpressService.sentGiftOrdersToHiExpress(orderId);
    dispatch(getOrderById(orderId, true));
    dispatch(showSuccessSnackbar("Order created successfully"));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};
