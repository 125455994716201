import React, { useState } from "react";
import {
  Menu,
  MenuItem,
  IconButton,
  Icon,
  ListItemIcon,
  ListItemText,
  Box,
} from "@mui/material";
import { HiCurrencyDollar } from "react-icons/hi";
import {
  LocalPrintshop as LocalPrintshopIcon,
  Receipt as ReceiptIcon,
} from "@mui/icons-material";
import { FaShoppingBag } from "react-icons/fa";
import { MdOutlineStarRate } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import RolePermission from "src/components/RolePermission";
import { openDialog } from "src/actions/orderAddtionalFees";
import { BsTelegram } from "react-icons/bs";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { openCODdialog } from "src/actions/gifts/orders";
import { showErrorSnackbar } from "src/actions/snackbarMessages";

const OrderActionsMenu = ({
  order,
  _UserInfo,
  setOpenInvoiceDialog,
  setOpenLabelDialog,
  setOpenTimelineDialog,
  setOpenRatingDialog,
  setRatingData,
  onSuccess,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton onClick={handleClick}>
        <Icon>menu</Icon>
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <RolePermission permission="58-01">
          <MenuItem
            onClick={() => {
              dispatch(openDialog("global"));
              handleClose();
            }}
          >
            <ListItemIcon>
              <HiCurrencyDollar size={25} />
            </ListItemIcon>
            <ListItemText>Order Additional Fees</ListItemText>
          </MenuItem>
        </RolePermission>
        {_UserInfo.roles.includes("PrintLabelAndInvoice") && [
          <MenuItem
            key="print-invoice"
            onClick={() => {
              setOpenInvoiceDialog(true);
              handleClose();
            }}
          >
            <ListItemIcon>
              <LocalPrintshopIcon />
            </ListItemIcon>
            <ListItemText>Print Invoice</ListItemText>
          </MenuItem>,
          <MenuItem
            key="print-label"
            onClick={() => {
              setOpenLabelDialog(true);
              handleClose();
            }}
          >
            <ListItemIcon>
              <ReceiptIcon />
            </ListItemIcon>
            <ListItemText>Print Label</ListItemText>
          </MenuItem>,
        ]}
        <RolePermission permission="22-03">
          <MenuItem
            onClick={() => {
              setOpenTimelineDialog(true);
              handleClose();
            }}
          >
            <ListItemIcon>
              <Icon>show_chart</Icon>
            </ListItemIcon>
            <ListItemText>Timeline</ListItemText>
          </MenuItem>
        </RolePermission>
        <RolePermission permission="34-12">
          <MenuItem
            onClick={() => {
              navigate(
                `/app/wallet/${order.customer ? order.customer.id : null}`
              );
              handleClose();
            }}
          >
            <ListItemIcon>
              <Icon>wallet</Icon>
            </ListItemIcon>
            <ListItemText>Go to wallet</ListItemText>
          </MenuItem>
        </RolePermission>
        <RolePermission permission="22-04">
          <MenuItem
            onClick={() => {
              navigate(`/app/user_orders/${order.customer.id}`);
              handleClose();
            }}
          >
            <ListItemIcon>
              <FaShoppingBag size={20} />
            </ListItemIcon>
            <ListItemText>User Orders</ListItemText>
          </MenuItem>
        </RolePermission>
        {order?.isCod ? (
          <RolePermission permission="22-17">
            <MenuItem
              onClick={() => {
                if (
                  order.statusNumber !== 2 &&
                  order?.remainingCODAmount > 0 &&
                  _UserInfo?.roles.includes("SystemAdmin")
                ) {
                  handleClose();
                  return dispatch(
                    showErrorSnackbar("Delivered - الرجاء تحويل حالة الطلب الى")
                  );
                } else {
                  dispatch(openCODdialog());
                  handleClose();
                }
              }}
            >
              <ListItemIcon>
                <FaShoppingBag size={20} />
              </ListItemIcon>
              <ListItemText>Update Remaining COD Amount</ListItemText>
            </MenuItem>
          </RolePermission>
        ) : null}
        {order?.orderRating?.length > 0 && (
          <MenuItem
            onClick={() => {
              setOpenRatingDialog(true);
              setRatingData(order?.orderRating[0]);
              handleClose();
            }}
          >
            <ListItemIcon>
              <MdOutlineStarRate />
            </ListItemIcon>
            <ListItemText>Order Rating</ListItemText>
          </MenuItem>
        )}
        <MenuItem
          onClick={(e) => {
            window.open(
              `https://t.me/+964${order?.customer.phoneNumber}`,
              "_blank"
            );
          }}
        >
          <ListItemIcon>
            <BsTelegram color="#229ED9" size={20} />
          </ListItemIcon>
          <ListItemText>Chat Via Telegram</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            window.open(
              `https://wa.me/+964${order?.customer.phoneNumber}`,
              "_blank"
            );
          }}
        >
          <ListItemIcon>
            <WhatsAppIcon color="success" />
          </ListItemIcon>
          <ListItemText>Chat Via Whatsapp</ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default OrderActionsMenu;
